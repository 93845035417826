import { useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { ClaimBondTableData, ClaimBondCardData } from "./ClaimRow";
import { txnButtonText, isPendingTxn, txnButtonTextGeneralPending } from "src/slices/PendingTxnsSlice";
import { redeemAllBonds, redeemBond } from "src/slices/BondSlice";
import { calculateUserBondDetails } from "src/slices/AccountSlice";
import CardHeader from "../../components/CardHeader/CardHeader";
import { useWeb3Context } from "src/hooks/web3Context";
import useBonds from "src/hooks/Bonds";
import {
  Button,
  Box,
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Zoom,
  Typography,
  Pagination,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./choosebond.scss";
import { useSelector, useDispatch } from "react-redux";
import NoData from "src/assets/images/noData.svg";
import { LoadingButton } from "@mui/lab";
import { useMobile } from "../../hooks";
import { styled } from "@material-ui/core";
import { CustormPagination } from "src/components";

function ClaimBonds({ activeBonds, directBonds, count, changePage, directBondCount, directBondChangePage }) {
  const { isSmallScreen } = useMobile();

  let isClaimAllNumber = 0;
  activeBonds.map(_ => {
    isClaimAllNumber += Number(_.pendingPayout);
  });
  const [activeBond, setActiveBond] = useState(0);
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();
  const { bonds } = useBonds(chainID);
  const [numberOfBonds, setNumberOfBonds] = useState(0);
  const [numberOfDirectBonds, setNumberOfDirectBonds] = useState(0);
  // const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "redeem_all_bonds") ||
      isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
    ) {
      return true;
    }

    return false;
  };

  const onRedeemAll = async ({ autostake }) => {
    // console.log("redeeming all bonds");

    await dispatch(redeemAllBonds({ address, bonds, networkID: chainID, provider, autostake }));

    // console.log("redeem all complete");
  };

  useEffect(() => {
    let bondCount = Object.keys(activeBonds).length;
    setNumberOfBonds(bondCount);
  }, [activeBonds]);

  useEffect(() => {
    let directBondCount = Object.keys(directBonds).length;
    setNumberOfDirectBonds(directBondCount);
  }, [directBonds]);

  return (
    <Box
      sx={{
        px: isSmallScreen ? 1.875 : 0,
        width: "100%",
        mb: 5,
        pb: isSmallScreen ? 0 : 5,
        // mb: isSmallScreen ? 5 : 0,
      }}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", maxWidth: "833px", mb: 2.5 }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "392px",
            borderRadius: "44px",
            border: "1px solid #3E434E",
            background: "#2E3138",
            boxShadow: "2px 4px 8px 0px #1A1C1F inset, -1px -1px 4px 0px #3E434E inset",
            p: 0.675,
          }}
          display={"flex"}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              width: "100%",
              height: "28px",
              borderRadius: "15px",
              border: activeBond == 0 ? "1px solid #5F6674" : "none",
              background: activeBond == 0 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
              boxShadow: activeBond == 0 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
              cursor: "pointer",
            }}
            onClick={() => setActiveBond(0)}
            display={"flex"}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              sx={{
                color: "#C7C8CC",
                fontFamily: "AGaramond LT",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              <Trans>Your Bond</Trans>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "28px",
              borderRadius: "15px",
              border: activeBond == 1 ? "1px solid #5F6674" : "none",
              background: activeBond == 1 ? "linear-gradient(0deg, #464B55 0%, #101114 100%)" : "transparent",
              boxShadow: activeBond == 1 ? "0px 2px 8px 0px rgba(26, 28, 31, 0.80)" : "none",
              cursor: "pointer",
            }}
            onClick={() => setActiveBond(1)}
            display={"flex"}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              sx={{
                color: "#C7C8CC",
                fontFamily: "AGaramond LT",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              <Trans>Direct Bond Award</Trans>
            </Typography>
          </Box>
        </Box>
      </Box> */}
      {/* {numberOfBonds > 0 && ( */}
      <Zoom in={true}>
        <Paper
          className="ohm-card linear-card"
          sx={{
            minHeight: "360px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: isSmallScreen ? "12px" : "24px",
              //  border: "2px solid #434952",
              background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
              // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
              px: isSmallScreen ? 2.5 : 3.75,
              py: 2.5,
            }}
          >
            <CardHeader title={activeBond === 0 ? t`Your Bonds` : t`Direct Bond Award`} />
            <Box
              sx={{ minHeight: "350px" }}
              display="flex"
              alignItems="center"
              flexDirection={"column"}
              justifyContent="space-between"
            >
              {!isSmallScreen && (
                <TableContainer>
                  <Table aria-label="Claimable bonds">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#868B8F",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            borderBottom: "none",
                          }}
                        >
                          <Trans>Bond</Trans>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#868B8F",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            borderBottom: "none",
                          }}
                        >
                          <Trans>Claimable</Trans>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#868B8F",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            borderBottom: "none",
                          }}
                        >
                          <Trans>Pending</Trans>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: "#868B8F",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            borderBottom: "none",
                          }}
                        >
                          <Trans>Fully Vested</Trans>
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: "none" }}></TableCell>
                      </TableRow>
                    </TableHead>
                    {0 === 0 ? (
                      <TableBody>
                        {numberOfBonds > 0
                          ? Object.entries(activeBonds).map((bond, i) => <ClaimBondTableData key={i} userBond={bond} />)
                          : !isSmallScreen && (
                              <TableRow rowSpan={4}>
                                <TableCell
                                  colSpan={4}
                                  sx={{
                                    height: "197px",
                                    borderBottom: "none",
                                  }}
                                >
                                  <Box
                                    sx={{ width: "100%", height: "100%" }}
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    flexDirection={"column"}
                                  >
                                    <img src={NoData} alt="No Data" />
                                    <Typography
                                      sx={{
                                        pt: 2,
                                        color: "#C7C8CC",
                                        fontFamily: "AGaramond LT",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                      }}
                                    >
                                      <Trans>No data</Trans>
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                      </TableBody>
                    ) : (
                      <TableBody>
                        {numberOfDirectBonds > 0
                          ? Object.entries(directBonds).map((bond, i) => (
                              <ClaimBondTableData key={i} userBond={bond} isInvite={true} />
                            ))
                          : !isSmallScreen && (
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  sx={{
                                    height: "197px",
                                    borderBottom: "none",
                                  }}
                                >
                                  <Box
                                    sx={{ width: "100%", height: "100%" }}
                                    display="flex"
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    flexDirection={"column"}
                                  >
                                    <img src={NoData} alt="No Data" />
                                    <Typography
                                      sx={{
                                        pt: 2,
                                        color: "#C7C8CC",
                                        fontFamily: "AGaramond LT",
                                        fontSize: "12px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                      }}
                                    >
                                      <Trans>No data</Trans>
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              )}

              {isSmallScreen &&
                (0 === 0 ? (
                  Object.entries(activeBonds).length > 0 ? (
                    Object.entries(activeBonds).map((bond, i) => (
                      <Box
                        key={'soabk_'+i}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <ClaimBondCardData key={i} userBond={bond} />
                        {i < Object.keys(activeBonds).length - 1 && (
                          <Box
                            sx={{
                              width: "100%",
                              height: "1px",
                              background: "#0b0c0a",
                              marginBottom: "10px",
                              boxShadow: "0px -1px 0px 0px rgba(83, 89, 102, 0.60)",
                            }}
                          />
                        )}
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{ width: "100%", height: "200px" }}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <img src={NoData} alt="No Data" />
                      <Typography
                        sx={{
                          pt: 2,
                          color: "#C7C8CC",
                          fontFamily: "AGaramond LT",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        <Trans>No data</Trans>
                      </Typography>
                    </Box>
                  )
                ) : Object.entries(directBonds).length > 0 ? (
                  Object.entries(directBonds).map((bond, i) => (
                    <Box
                      key={'sodbk_'+i}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <ClaimBondCardData key={i} userBond={bond} isInvite />
                      {i < Object.keys(directBonds).length - 1 && (
                        <Box
                          sx={{
                            width: "100%",
                            height: "1px",
                            background: "#0b0c0a",
                            marginBottom: "10px",
                            boxShadow: "0px -1px 0px 0px rgba(83, 89, 102, 0.60)",
                          }}
                        />
                      )}
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{ width: "100%", height: "200px" }}
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                  >
                    <img src={NoData} alt="No Data" />
                    <Typography
                      sx={{
                        pt: 2,
                        color: "#C7C8CC",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      }}
                    >
                      <Trans>No data</Trans>
                    </Typography>
                  </Box>
                ))}
              {numberOfBonds > 1 && activeBond === 0 && isSmallScreen && (
                <Box
                  sx={{
                    width: "100%",
                    height: "1px",
                    background: "#0B0C0A",
                    boxShadow: "0px -1px 0px 0px rgba(83, 89, 102, 0.60)",
                    mb: 1.25,
                  }}
                />
              )}
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {activeBond === 0 && !!activeBonds.length && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      mb: 2,
                    }}
                  >
                    <CustormPagination
                      siblingCount={isSmallScreen ? 0 : 1}
                      boundaryCount={1}
                      count={count}
                      onChange={changePage}
                    />
                  </Box>
                )}
                {activeBond === 1 && !!directBonds.length && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      mb: 2,
                    }}
                  >
                    <CustormPagination
                      siblingCount={isSmallScreen ? 0 : 1}
                      boundaryCount={1}
                      count={directBondCount}
                      onChange={directBondChangePage}
                    />
                  </Box>
                )}
                {numberOfBonds > 0 && activeBond === 0 && !isSmallScreen && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    className={`global-claim-buttons ${isSmallScreen ? "small" : ""}`}
                    flexDirection={isSmallScreen ? "column" : "row"}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <>
                      <LoadingButton
                        variant="contained"
                        sx={{
                          height: "32px",
                          borderRadius: "30px",
                          border: "1.3px solid #B50C05",
                          background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                          boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                        }}
                        className="transaction-button"
                        fullWidth
                        disabled={
                          pendingClaim() || isClaimAllNumber <= 0 || isPendingTxn(pendingTransactions, "redeem_bond")
                        }
                        loading={pendingClaim() || isPendingTxn(pendingTransactions, "redeem_bond")}
                        loadingPosition="end"
                        onClick={() => {
                          onRedeemAll({ autostake: false });
                        }}
                      >
                        {txnButtonTextGeneralPending(pendingTransactions, "redeem_all_bonds", t`Claim all`)}
                      </LoadingButton>

                      <LoadingButton
                        variant="contained"
                        sx={{
                          height: "32px",
                          borderRadius: "30px",
                          border: "1.3px solid #B50C05",
                          background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                          boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                        }}
                        id="claim-all-and-stake-btn"
                        className="transaction-button"
                        fullWidth
                        disabled={
                          pendingClaim() || isClaimAllNumber <= 0 || isPendingTxn(pendingTransactions, "redeem_bond")
                        }
                        loading={pendingClaim() || isPendingTxn(pendingTransactions, "redeem_bond")}
                        loadingPosition="end"
                        onClick={() => {
                          onRedeemAll({ autostake: true });
                        }}
                      >
                        {txnButtonTextGeneralPending(
                          pendingTransactions,
                          "redeem_all_bonds_autostake",
                          t`Claim all and Stake`,
                        )}
                      </LoadingButton>
                    </>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Zoom>
      
    </Box>
  );
}

export default ClaimBonds;
