import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { Box, Button, FormControl, InputAdornment, InputLabel, OutlinedInput, Slide, Typography } from "@mui/material";
import { prettifySeconds, secondsUntilBlock, shorten, trim } from "../../helpers";
import { bondAsset, calcBondDetails, changeApproval, setBondState } from "../../slices/BondSlice";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@mui/material";
import useDebounce from "../../hooks/Debounce";
import { error } from "../../slices/MessagesSlice";
import { DisplayBondDiscount } from "./Bond.jsx";
import ConnectButton from "../../components/ConnectButton";
import DownIcon from "../../assets/icons/down.png";
import "./bondPurchase.scss";
import { useMobile } from "../../hooks";
import { LoadingButton } from "@mui/lab";

function BondPurchase({ bond, slippage, recipientAddress, theme }) {
    const { provider, address, chainID } = useWeb3Context();
    // console.log('[debug]web3context return:',address);
    const { isSmallScreen } = useMobile();
    // console.log("BondPurchase", bond);
    const isLight = theme === "light" ? true : false;
    const SECONDS_TO_REFRESH = 60;
    const dispatch = useDispatch();

    // console.log('[debug]connect web3 context return:',address)

    const [isShowSelect, changeShowSelect] = useState(false);
    const [selVal, setSelVal] = useState(0);
    const [tokenVal, setTokenVal] = useState(bond.displayName);
    const [quantity, setQuantity] = useState("");
    const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);

    const currentBlock = useSelector(state => {
        return state.app.currentBlock;
    });
    useEffect(() => {
        console.log("bond.displayName", bond.displayName, bond);
        setTokenVal(bond.name.startsWith("lgns_dai_lp") ? "DAI" : bond.displayName);
    }, [bond.name]);
    const isBondLoading = useSelector(state => state.bonding.loading ?? true);

    const pendingTransactions = useSelector(state => {
        return state.pendingTransactions;
    });

    const vestingPeriod = () => {
        const vestingBlock = parseInt(currentBlock) + parseInt(bond.vestingTerm);
        const seconds = secondsUntilBlock(currentBlock, vestingBlock);
        return prettifySeconds(seconds, "day");
    };

    async function onBond() {
        // let maxQ;
        // if (bond.maxBondPrice * bond.bondPrice < Number(bond.balance)) {
        //   // there is precision loss here on Number(bond.balance)
        //   maxQ = bond.maxBondPrice * bond.bondPrice.toString();
        // } else {
        //   maxQ = bond.balance;
        // }
        // console.log(
        //   "quantity > trim(bond.maxBondPrice, 4)",
        //   quantity,
        //   trim(bond.maxBondPrice, 4),
        //   bond.bondQuote,
        //   bond.maxBondPrice,
        // );

        console.log("bondAsset", quantity > bond[0].balance[selVal], quantity, bond[0].balance);

        if (quantity === "") {
            dispatch(error(t`Please enter a value!`));
            return;
            // } else if (isNaN(quantity) || quantity <= 0 || quantity > maxQ) {
        } else if (isNaN(quantity) || quantity <= 0) {
            dispatch(error(t`Please enter a valid value!`));
            return;
        } else if (quantity > Number(bond[0].balance[selVal])) {
            dispatch(error(t`You don't have enough Balance!`));
            return;
        } else if (bond.bondQuote > bond.maxBondPrice) {
            // "You're trying to bond more than the maximum payout available! The maximum bond payout is " +
            //   (maxBondPrice / Math.pow(10, 9)).toFixed(2).toString() +
            //   " LGNS.";

            dispatch(
                error(
                    `${t`You're trying to bond more than the maximum payout available! The maximum bond payout is`} ${trim(
                        bond.maxBondPrice,
                        4,
                    )} LGNS`,
                ),
            );
            return;
        } else if (bond.interestDue > 0 || bond.pendingPayout > 0) {
            const shouldProceed = window.confirm(
                t`You have an existing bond. Bonding will reset your vesting period and forfeit rewards. We recommend claiming rewards first or using a fresh wallet. Do you still want to proceed?`,
            );
            if (shouldProceed) {
                await dispatch(
                    bondAsset({
                        value: quantity,
                        slippage,
                        bond,
                        networkID: chainID,
                        provider,
                        selVal,
                        address: recipientAddress || address,
                    }),
                );
            }
        } else {
            console.log("bondAsset dispatch");
            await dispatch(
                bondAsset({
                    value: quantity,
                    slippage,
                    bond,
                    networkID: chainID,
                    provider,
                    selVal,
                    address: recipientAddress || address,
                }),
            );
            //!!!!!!!TODO
            dispatch(calcBondDetails({ bond, value: 0, provider, networkID: chainID, selVal }));

            clearInput();
        }
    }

    const clearInput = () => {
        setQuantity(0);
        // dispatch(
        //   setBondState({
        //     bond: {
        //       ...bond,
        //       bondQuote: 0,
        //     },
        //   }),
        // );
    };

    // const hasAllowance = useCallback(() => {
    //   return bond.allowance > 0;
    // }, [bond.allowance]);

    const setMax = () => {
        // let maxQ;
        // if (bond.maxBondPrice * bond.bondPrice < Number(bond.balance)) {
        //   // there is precision loss here on Number(bond.balance)
        //   maxQ = bond.maxBondPrice * bond.bondPrice.toString();
        // } else {
        //   maxQ = bond.balance[selVal];
        // }
        // setQuantity(maxQ);
        console.log("bond max", bond, selVal);
        let maxQ;
        if (Number(selVal) <= 1) {
            if (!bond.name.startsWith("lgns_dai_lp") && bond.name !== "usdt") {
                console.log(
                    "bond.maxBondPrice * bond.bondPrice < Number(bond[0].balance[selVal]) * bond.tokenPrice",
                    bond.maxBondPrice * bond.bondPrice < Number(bond[0].balance[selVal]) * bond.tokenPrice,
                );
                if (bond.maxBondPrice * bond.bondPrice < Number(bond[0].balance[selVal]) * bond.tokenPrice) {
                    // there is precision loss here on Number(bond.balance)
                    maxQ = (bond.maxBondPrice * bond.bondPrice) / bond.tokenPrice;
                } else {
                    maxQ = bond[0].balance[selVal];
                }
            } else {
                if (bond.maxBondPrice * bond.bondPrice < Number(bond[0].balance[selVal])) {
                    // there is precision loss here on Number(bond.balance)
                    maxQ = bond.maxBondPrice * bond.bondPrice.toString();
                } else {
                    maxQ = bond[0].balance[selVal];
                }
            }
        } else {
            if (bond.maxBondPrice * bond.bondPrice < Number(balances[selVal - 2])) {
                // there is precision loss here on Number(bond.balance)
                maxQ = bond.maxBondPrice * bond.bondPrice.toString();
            } else {
                maxQ = balances[selVal - 2];
            }
        }
        console.log("maxQ:", maxQ);
        setQuantity(trim(maxQ.toString(), 4));
        // setQuantity(maxQ.toString());
    };

    const bondDetailsDebounce = useDebounce(quantity, 1000);

    useEffect(() => {
        dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: chainID, selVal }));
    }, [bondDetailsDebounce]);

    useEffect(() => {
        let interval = null;
        if (secondsToRefresh > 0) {
            interval = setInterval(() => {
                setSecondsToRefresh(secondsToRefresh => secondsToRefresh - 1);
            }, 1000);
        } else {
            clearInterval(interval);
            dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: chainID, selVal }));
            setSecondsToRefresh(SECONDS_TO_REFRESH);
        }
        return () => clearInterval(interval);
    }, [secondsToRefresh, quantity]);

    const onSeekApproval = async () => {
        dispatch(changeApproval({ address, bond, provider, networkID: chainID, selVal }));
    };

    const displayUnits = bond.displayUnits;
    let isAllowanceDataLoading;
    console.log('[debug]bonddebug:',bond);
    if (typeof bond == "object" && bond[0]) {
        isAllowanceDataLoading = bond[0].allowance == null;
    } else {
        isAllowanceDataLoading = bond.allowance == null;
    }
    // const isAllowanceDataLoading = bond.allowance == null;
    // console.log("bond allow", bond);
    // const isAllowanceDataLoading = false;
    const handlerSelect = () => {
        changeShowSelect(!isShowSelect);
    };

    const handleChange = (number, str) => {
        setSelVal(number);
        setTokenVal(str);
        changeShowSelect(false);
        clearInput();
        // dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: chainID, selVal: event.target.value }));
    };

    const hasAllowance = useCallback(() => {
        // console.log("hasAllowance", bond, bond[0]);
        if (typeof bond == "object" && bond[0]) {
            // isAllowanceDataLoading = bond[0].allowance == null;
            return !bond[0].allowance ? false : bond[0].allowance[selVal] > 0;
        } else {
            // isAllowanceDataLoading = bond.allowance == null;
            return !bond.allowance ? false : bond.allowance[selVal] > 0;
        }
        // if (selVal != 0) {
        // return !bond.allowance ? false : bond.allowance[selVal] > 0;
        // } else {
        // return true;
        // }
    }, [selVal]);

    const handleOnchange = event => {
        let value = event.target.value.replace(/[^\d.]/g, "");
        if (value < 0) {
            setQuantity("");
            return;
        }
        setQuantity(value);
    };
    // console.log('[debug]bond address:',address);
    // console.log('[debug]bond isAllowanceDataLoading:',isAllowanceDataLoading);
    return (
        <Box display="flex" flexDirection="column">
            <Box
                display="flex"
                flexDirection={!address || isAllowanceDataLoading ? "column" : "row"}
                justifyContent="space-around"
                alignItems={!address || isAllowanceDataLoading ? "center" : "flex-start"}
                flexWrap="wrap"
            >
                {!address ? (
                    <Box
                        sx={{
                            width: "100%",
                            // maxWidth: "500px",
                        }}
                        display="flex"
                        justifyContent={"center"}
                    >
                        <ConnectButton />
                    </Box>
                ) : (
                    <>
                        {isAllowanceDataLoading ? (
                            <Skeleton width="200px" />
                        ) : (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                flexDirection={isSmallScreen ? "column" : "row"}
                                alignItems="center"
                                sx={{ width: "100%" }}
                            >
                                {/* {!hasAllowance() ? (
                  <div className="help-text">
                    <em>
                      <Typography variant="body1" align="center" color="textSecondary">
                        <Trans>First time bonding</Trans> <b>{bond.displayName}</b>? <br />{" "}
                        <Trans>Please approve Xenophon Dao to use your</Trans> <b>{bond.displayName}</b>{" "}
                        <Trans>for bonding</Trans>.
                      </Typography>
                    </em>
                  </div>
                ) : ( */}
                                <FormControl
                                    className="ohm-input"
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    sx={{
                                        height: "40px",
                                        m: "0px !important",
                                        mr: "15px !important",
                                        mb: isSmallScreen ? "20px !important" : 0,
                                    }}
                                >
                                    <InputLabel
                                        htmlFor="outlined-adornment-amount"
                                        sx={{
                                            top: "-7px",
                                            color: "#C7C8CC",
                                            fontFamily: "SF Pro Display",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                            lineHeight: "normal",
                                        }}
                                    >
                                        <Trans>Amount</Trans>
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-amount"
                                        type="number"
                                        sx={{
                                            width: "100%",
                                            height: "42px",
                                            borderRadius: "60px",
                                            border: "1px solid rgba(138, 147, 160, 0.50)",
                                            background: "#3C3F48",
                                            color: "#c7c8cc",
                                            // mb: 1,
                                            boxShadow: "2px 4px 8px 0px #1A1C1F inset, -2px -2px 4px 0px #3E434E inset",
                                            '& input[type="number"]': {
                                                py: 0,
                                            },
                                        }}
                                        value={quantity}
                                        min={0}
                                        onChange={handleOnchange}
                                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        // labelWidth={55}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Button
                                                    variant="text"
                                                    onClick={setMax}
                                                    sx={{
                                                        color: "#B50C05",
                                                        fontFamily: "Euclid Square",
                                                        fontSize: "16px",
                                                        fontStyle: "normal",
                                                        fontWeight: "400",
                                                    }}
                                                >
                                                    <Trans>Max</Trans>
                                                </Button>
                                                <Box className="selectBox">
                                                    <Box display="flex" className="selectVal" onClick={handlerSelect}>
                                                        <Typography
                                                            sx={{
                                                                color: "#C7C8CC",
                                                                fontFamily: "SF Pro Display",
                                                                fontSize: "16px",
                                                                fontStyle: "normal",
                                                                fontWeight: "400",
                                                                lineHeight: "normal",
                                                            }}
                                                        >
                                                            {tokenVal}
                                                        </Typography>
                                                        <img
                                                            src={DownIcon}
                                                            className={["DownIcon", isShowSelect ? "select-caret" : ""].join(" ")}
                                                        ></img>
                                                    </Box>
                                                    <Box
                                                        className={[
                                                            "selectList",
                                                            isShowSelect ? "show" : "",
                                                            !isLight ? "selectListDark" : "",
                                                        ].join(" ")}
                                                    >
                                                        <Box
                                                            className="selectItem"
                                                            onClick={() => {
                                                                handleChange(0, !bond.nam.startsWith("lgns_dai_lp") ? bond.displayName : "DAI");
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: "#C7C8CC",
                                                                    fontFamily: "SF Pro Display",
                                                                    fontSize: "16px",
                                                                    fontStyle: "normal",
                                                                    fontWeight: "400",
                                                                    lineHeight: "normal",
                                                                }}
                                                            >
                                                                {!bond.name.startsWith("lgns_dai_lp") ? bond.displayName : "DAI"}
                                                            </Typography>
                                                        </Box>
                                                        {!bond.name.startsWith("lgns_dai_lp") ? (
                                                            ""
                                                        ) : (
                                                            <Box>
                                                                <Box
                                                                    className="selectItem"
                                                                    onClick={() => {
                                                                        handleChange(1, "USDT");
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            color: "#C7C8CC",
                                                                            fontFamily: "SF Pro Display",
                                                                            fontSize: "16px",
                                                                            fontStyle: "normal",
                                                                            fontWeight: "400",
                                                                            lineHeight: "normal",
                                                                        }}
                                                                    >
                                                                        USDT
                                                                    </Typography>
                                                                </Box>
                                                                {/* <Box
                                  className="selectItem"
                                  onClick={() => {
                                    handleChange(2, "USDC");
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#C7C8CC",
                                      fontFamily: "SF Pro Display",
                                      fontSize: "16px",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      lineHeight: "normal",
                                    }}
                                  >
                                    USDC
                                  </Typography>
                                </Box> */}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                {/* )} */}
                                {
                                    // console.log('[debug]render:bond.isAvailable',bond?.isAvailable)
                                }
                                {
                                    // console.log('[debug]render:isAllowanceDataLoading',isAllowanceDataLoading)
                                }
                                {!bond.isAvailable[chainID] ? (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            borderRadius: "30px",
                                            border: "1.3px solid #B50C05",
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                            width: "100%",
                                            maxWidth: isSmallScreen ? "100%" : "240px",
                                        }}
                                        id="bond-btn"
                                        className="main-btn transaction-button"
                                        disabled={true}
                                    >
                                        <Trans>Sold Out</Trans>
                                    </Button>
                                ) : isAllowanceDataLoading ? (
                                    <Skeleton width="200px" />
                                ) : hasAllowance() ? (
                                    <LoadingButton
                                        variant="contained"
                                        sx={{
                                            borderRadius: "30px",
                                            border: "1.3px solid #B50C05",
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                            width: "100%",
                                            maxWidth: isSmallScreen ? "100%" : "240px",
                                        }}
                                        id="bond-btn"
                                        className="transaction-button main-btn"
                                        disabled={isPendingTxn(pendingTransactions, "bond_" + bond.name)}
                                        loading={isPendingTxn(pendingTransactions, "bond_" + bond.name)}
                                        loadingPosition="end"
                                        onClick={onBond}
                                    >
                                        {txnButtonText(pendingTransactions, "bond_" + bond.name, t`Buy_Bond`)}
                                    </LoadingButton>
                                ) : (
                                    <LoadingButton
                                        variant="contained"
                                        sx={{
                                            borderRadius: "30px",
                                            border: "1.3px solid #B50C05",
                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                            width: "100%",
                                            maxWidth: isSmallScreen ? "100%" : "240px",
                                        }}
                                        id="bond-approve-btn"
                                        className="transaction-button"
                                        disabled={isPendingTxn(pendingTransactions, "approve_" + bond.name)}
                                        loading={isPendingTxn(pendingTransactions, "approve_" + bond.name)}
                                        loadingPosition="end"
                                        onClick={onSeekApproval}
                                    >
                                        {txnButtonText(pendingTransactions, "approve_" + bond.name, t`Approve`)}
                                    </LoadingButton>
                                )}
                            </Box>
                        )}
                    </>
                )}
                {!hasAllowance() && (
                    <div className="help-text">
                        <em>
                            {bond.name !== "lgns_dai_lp" ? (
                                <Typography sx={{ color: "#868b8f", fontSize: "14px", fontWeight: "400", width: "100%" }}>
                                    <Trans>Note:</Trans> <br />
                                    <Trans>1.First time bonding</Trans> <b>{bond.displayName}</b>? <br />{" "}
                                    <Trans>2.Please approve Origin to use your</Trans> <b>{bond.displayName}</b>{" "}
                                    <Trans>for bonding</Trans>.
                                </Typography>
                            ) : (
                                <Typography sx={{ color: "#868b8f", fontSize: "14px", fontWeight: "400", width: "100%" }}>
                                    <Trans>Note:</Trans>
                                    <br />
                                    <Trans>1. Create LP with a single click using DAI. You don't need to create LP manually.</Trans>
                                    <br />
                                    <Trans>
                                        2. The "Approve" transaction is only needed when bonding for the first time; subsequent bonding only
                                        requires you to perform the "Bond" transaction.
                                    </Trans>
                                </Typography>
                            )}
                        </em>
                    </div>
                )}
                {/* <Trans>First time bonding</Trans> <b>{bond.displayName}</b>? <br />{" "}
                        <Trans>Please approve Xenophon Dao to use your</Trans> <b>{bond.displayName}</b>{" "}
                        <Trans>for bonding</Trans>. */}
            </Box>

            <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 533 }}>
                <Box className="bond-data">
                    <div className="data-row">
                        <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                            <Trans>Your Balance</Trans>
                        </Typography>{" "}
                        <Typography
                            id="bond-balance"
                            sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                        >
                            {/* {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                <>
                  {trim(bond.balance, 4)} {displayUnits}
                </>
              )} */}
                            {isBondLoading && (!bond[0] || !bond.balance) ? (
                                <Skeleton width="100px" />
                            ) : (
                                <>
                                    {bond[0] && bond[0].balance ? trim(bond[0].balance[selVal], 4) : 0}
                                    {bond.name.startsWith("lgns_dai_lp") ? (selVal == "0" ? "DAI" : tokenVal) : bond.bondToken}

                                    {/* {!bond.balance ? 0 : trim(bond.balance[selVal], 4)} {selVal == "0" ? "BUSD" : "LP"}{" "} */}
                                    {/* {bond.displayUnits} */}
                                </>
                            )}
                        </Typography>
                    </div>

                    <div className={`data-row`}>
                        <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                            <Trans>You Will Get</Trans>
                        </Typography>
                        <Typography
                            id="bond-value-id"
                            className="price-data"
                            sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                        >
                            {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.bondQuote, 4) || "0"} LGNS`}
                        </Typography>
                    </div>

                    <div className={`data-row`}>
                        <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                            <Trans>Max You Can Buy</Trans>
                        </Typography>
                        <Typography
                            id="bond-value-id"
                            className="price-data"
                            sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}
                        >
                            {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.maxBondPrice, 4) || "0"} LGNS`}
                        </Typography>
                    </div>

                    <div className="data-row">
                        <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                            <Trans>ROI</Trans>
                        </Typography>
                        <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                            {isBondLoading ? <Skeleton width="100px" /> : <DisplayBondDiscount key={bond.name} bond={bond} />}
                        </Typography>
                    </div>

                    <div className="data-row">
                        <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                            <Trans>Debt Ratio</Trans>
                        </Typography>
                        <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                            {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.debtRatio / 10000000, 2)}%`}
                        </Typography>
                    </div>

                    <div className="data-row">
                        <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                            <Trans>Vesting Term</Trans>
                        </Typography>
                        <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                            {isBondLoading ? <Skeleton width="100px" /> : vestingPeriod()}
                        </Typography>
                    </div>

                    {recipientAddress !== address && (
                        <div className="data-row">
                            <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                                <Trans>Recipient</Trans>{" "}
                            </Typography>
                            <Typography sx={{ color: "#c7c8cc", fontSize: "14px", fontWeight: "700", fontFamily: "AGaramond LT" }}>
                                {isBondLoading ? <Skeleton width="100px" /> : recipientAddress ? shorten(recipientAddress) : ""}
                            </Typography>
                        </div>
                    )}
                </Box>
            </Slide>
        </Box>
    );
}

export default BondPurchase;
