import { BigNumber } from "ethers";
import DaiBondContractJson from "src/abi/bonds/DaiContract.json";

// export const THE_GRAPH_URL = "https://api.studio.thegraph.com/query/67229/lgns-without-comm-metrics/v0.0.4.lgns-comm";
// export const THE_GRAPH_URL = "https://api.studio.thegraph.com/query/67229/lgns-metrics/v0.0.2.lgns";
export const THE_GRAPH_URL = "https://api.studio.thegraph.com/query/67229/lgns-without-comm-metrics/v0.0.5.lgns-comm";

// export const EPOCH_INTERVAL = 1920; //8h
// export const EPOCH_INTERVAL = 5760; //24h
// export const EPOCH_INTERVAL = 9600;
// polygon 14400
export const EPOCH_INTERVAL = 14400;

// NOTE could get this from an outside source since it changes slightly over time
//15 eth mainet
// 2 polygon mainnet
export const BLOCK_RATE_SECONDS = 2;

export const OHM_INITIAL_PRICE = BigNumber.from("1000000000000000000");

export const TOKEN_DECIMALS = 9;

interface IPoolGraphURLS {
  [index: string]: string;
}

export const DEFAULT_INVITE_ADDRESS = "0x495BEfc1bFAB4908d3848f8f2DCcc770B17cd0c0";

export const POOL_GRAPH_URLS: IPoolGraphURLS = {
  4: "https://api.thegraph.com/subgraphs/name/pooltogether/rinkeby-v3_4_3",
  1: "https://api.thegraph.com/subgraphs/name/pooltogether/pooltogether-v3_4_3",
};

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  137: {
    USDC_ADDRESS: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    DAI_ADDRESS: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
    commRootAddress: "0xC26522960F9263AF7C4AD94816f6Be6FD642F0F1",
    CURVE_STABLE_POOL_ADDRESS: "0x445fe580ef8d70ff569ab36e80c647af338db351",
    CURVE_CRYPTO_POOL_ADDRESS: "0x1d8b86e3D88cDb2d34688e87E72F388Cb541B7C8",
    accountant: "0x616ce012FBEdB13fc7f9ca5502297CF218a921fB",
    lotteryLiquidator: "0x321804B26337a25A350181c310Bb4017A9c8534D",
    COMMUNITY_ADDRESS: "0x6757165973042541EBdEC47b73283397b5Afd90E",
    pancakeRouter: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
    pancakeFac: "0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32",
    USDT_ADDRESS: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
    USDT_REAL_ADDRESS: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    ETH_PRICEFEED_ADDRESS: "0xF9680D99D6C9589e2a93a78A04A279e509205945",
    BTC_PRICEFEED_ADDRESS: "0xc907E116054Ad103354f2D350FD2514433D57F6f",
    BTC_ADDRESS: "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    ETH_ADDRESS: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    ratioOperator: "0x9dA64DF74565861708781B9Ad2e559b7328b97c4",
    buyFeeReceiver: "0x465eA4cD10dB67707953fbc41D24692a48313EdC",
    lampFundRecipient: "0x21814346C967D751b4164BDB813f02884b497A0C",
    discipleFundRecipient: "0x16409e5515C8028f446b78d4691A29C3690c04Ac",
    investmentRecipient: "0x5BdA574d916982BCa0BAB3dFe5C32E219d2c717E",
    marketRecipient: "0x9a11640C07553C071c025bFb049BeE980E277f9d",
    stableCoinFundRecipient: "0x91F1D2c2165B17a1eD2dC3B73Ae77224E6e1410E",
    BOND_POLICY_ACCOUNT: "0x9dA64DF74565861708781B9Ad2e559b7328b97c4",
    STAKE_POLICY_ACCOUNT: "0x9dA64DF74565861708781B9Ad2e559b7328b97c4",
    daoPoolWithdrawAccount: "0xBc7269B1aE5d419a661cB4E4B54401159B2DC012",
    multi_sign_treasury: "0xBc7269B1aE5d419a661cB4E4B54401159B2DC012",
    FEE_RECEIVER_ADDRESS: "0xA304823C775d13fc851379C75e2b6f300E223234",
    LOTTERY_ADDRESS: "0x280537D9641c3AA0f7ddEAa00602af1A60811c51",
    OHM_ADDRESS: "0xeB51D9A39AD5EEF215dC0Bf39a8821ff804A0F01",
    OHM_USDT_PAIR: "0x882df4B0fB50a229C3B4124EB18c759911485bFb",
    BONDINGCALC_ADDRESS: "0x30F7171b298e79db82F690C4a7BcaD053Bf3Ca7C",
    TURBINE_ADDRESS: "0x07Ff4e06865de4934409Aa6eCea503b08Cc1C78d",
    TREASURY_ADDRESS: "0x7B9B7d4F870A38e92c9a181B00f9b33cc8Ef5321",
    TUSDB_ADDRESS: "0x7C911C70cf65984B0D266d58c5692361491a3786",
    DISTRIBUTOR_ADDRESS: "0x8DEa9182ca68E101C4B351E4601f56044a5Dd611",
    SOHM_ADDRESS: "0x99a57E6C8558BC6689f894e068733ADf83C19725",
    COMMUNITY_REWARD_ADDRESS: "0xa49aba84930299c6c2d537456A3e681bB4500f15",
    DAO_POOL_ADDRESS: "0x0309Ca717d6989676194b88fD06029a88CEEfee6",
    STAKING_ADDRESS: "0x1964Ca90474b11FFD08af387b110ba6C96251Bfc",
    STAKING_WARMUP_ADDRESS: "0x81e77A6CeeD59000ba5136Eb735d18Fd9bf5021e",
    STAKING_HELPER_ADDRESS: "0x78654ed080C1d1c87907646FDb2Ae48Db3635341",
    REDEEM_HELPER_ADDRESS: "0x43A9fb83AAfc8e36207Db40d57a43b5E074132e3",
    MINT_REWARD_ADDRESS: "0x806FDAb92B0Fc7fBE4bbBE5117A54cAa9283d5a4",
    DAI_BOND_ADDRESS: "0x2EC37eAb8C1a3EA0eDa2296495bf7BE6c62e2788",
    DEPOSIT_HELPER_ADDRESS: "0x8e2222C8b1e73E187e5AdA270707C11232E84630",
    AIRDROP_ADDRESS: "0x0b11baAA990baeB7Fd917984117A9B21E359AEe8",
    USDT_STABLE_BOND_ADDRESS: "0xbE741d070c6d26109b95ddcD7B6da47395272f45",
    DAI_STABLE_BOND_ADDRESS: "0x59A6693CaA6305b1E4F16176D5228C32E24717E5",
    BTC_BOND_ADDRESS: "0x4337E95f99d28bE9a013a17306542F835f7e6373",
    ETH_BOND_ADDRESS: "0x2EaF2221f4A8234c2a4aF65DCA38Eaf3CCe52DF1",
    STAKING_EX_ADDRESS: "0x9D150752Dae5707CB1E6344d858ec6b8B5937a79",
    STAKING_EX2_ADDRESS: "0xA0AA450b62929006602e183Abab0Fc516AC08A03",
    WEBKEY_NFT_SHARD_ADDRESS: "0xefc8a02d31e8E42430b4A4DfDDD8F51E0C0DBb57",
    WEBKEY_NFT_ADDRESS: "0x6085f1886a801E4c31cbF5D08DCD5A3B4e627F4c",
    WEBKEY_NFT_ARTISAN_ADDRESS: "0x52964611477d734aFA6B39D0Eb1C03125747213C",
    "DAI30_STABLE_BOND_ADDRESS": "0x152Ed4367ae89FBB37055780dB228dc178cB4330",
    "DAI60_STABLE_BOND_ADDRESS": "0x5f0c7F5a6f9C31A28b59B51FceE090E1b3f1e160",
    "DAI120_STABLE_BOND_ADDRESS": "0x4116262358c9DcCEDBA558B1BC6Fd01d09C79F9D",
    "DAI240_STABLE_BOND_ADDRESS": "0xDc40a84b1E44e181a215b9B56e70C14e89DD0Fb0",
    "DAI360_STABLE_BOND_ADDRESS": "0xa9c9eFB2AeEc103116B58B85cfeEFBB1b1FD1433",
    "LP30_BOND_ADDRESS": "0x579906B27d5c9f88d3a1C21d9150aA5C4544399D",
    "LP30_DEPOSIT_HELPER_ADDRESS": "0xE92b2986d1c237A172687D93d5565d3827a66B98",
    "LP60_BOND_ADDRESS": "0xfe713fE10D7a06dC0Bf815B76e593a62ED7b70f8",
    "LP60_DEPOSIT_HELPER_ADDRESS": "0x15517A9b6D9340b7DE03dD6292f1b33883Ad3ceF",
    "LP120_BOND_ADDRESS": "0x418F8bD13AEB1a1B3d89013EAdf5ADfA179a4F59",
    "LP120_DEPOSIT_HELPER_ADDRESS": "0xc4981352a494e67f7D67B12B080883d48D50Ea04",
    "LP240_BOND_ADDRESS": "0x468c0ee3069Fbb8BC9A50f2cFf7004A5d6384026",
    "LP240_DEPOSIT_HELPER_ADDRESS": "0xba4510DDbE589619EF18c61e628350A9429f656C",
    "LP360_BOND_ADDRESS": "0x6c0Ac888b075c6b5141cbC1Da6170B6686aFd07D",
    "LP360_DEPOSIT_HELPER_ADDRESS": "0x183E15f6cA33434BCb1B01a03a3aa58A31E74436"
  },
  4: {
    DAI_ADDRESS: "0xB2180448f8945C8Cc8AE9809E67D6bd27d8B2f2C", // duplicate
    OHM_ADDRESS: "0xC0b491daBf3709Ee5Eb79E603D73289Ca6060932",
    STAKING_ADDRESS: "0xC5d3318C0d74a72cD7C55bdf844e24516796BaB2",
    STAKING_HELPER_ADDRESS: "0xf73f23Bb0edCf4719b12ccEa8638355BF33604A1",
    OLD_STAKING_ADDRESS: "0xb640AA9082ad720c60102489b806E665d67DCE32",
    SOHM_ADDRESS: "0x1Fecda1dE7b6951B248C0B62CaeBD5BAbedc2084",
    WSOHM_ADDRESS: "0xe73384f11Bb748Aa0Bc20f7b02958DF573e6E2ad",
    OLD_SOHM_ADDRESS: "0x8Fc4167B0bdA22cb9890af2dB6cB1B818D6068AE",
    MIGRATE_ADDRESS: "0x3BA7C6346b93DA485e97ba55aec28E8eDd3e33E2",
    DISTRIBUTOR_ADDRESS: "0x0626D5aD2a230E05Fb94DF035Abbd97F2f839C3a",
    BONDINGCALC_ADDRESS: "0xaDBE4FA3c2fcf36412D618AfCfC519C869400CEB",
    CIRCULATING_SUPPLY_ADDRESS: "0x5b0AA7903FD2EaA16F1462879B71c3cE2cFfE868",
    TREASURY_ADDRESS: "0x0d722D813601E48b7DAcb2DF9bae282cFd98c6E7",
    REDEEM_HELPER_ADDRESS: "0xBd35d8b2FDc2b720842DB372f5E419d39B24781f",
    PT_TOKEN_ADDRESS: "0x0a2d026bacc573a8b5a2b049f956bdf8e5256cfd", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
    PT_PRIZE_POOL_ADDRESS: "0xf9081132864ed5e4980CFae83bDB122d86619281", // NEW
    PT_PRIZE_STRATEGY_ADDRESS: "0x2Df17EA8D6B68Ec444c9a698315AfB36425dac8b", // NEW
    PreSale_ADDRESS: "",
  },

  97: {
    DAO_WALLET: "0x495BEfc1bFAB4908d3848f8f2DCcc770B17cd0c0",
    tusdbRecipient: "0x38bEDCF21f54744697C50Ef166876F4B4BC1dAc2",
    accountant: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    lotteryLiquidator: "0x3FE4786a158bE5e4912157468E8e2d5F46cBe394",
    commRootAddress: "0x495BEfc1bFAB4908d3848f8f2DCcc770B17cd0c0",
    ratioOperator: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    lampFundRecipient: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    discipleFundRecipient: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    investmentRecipient: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    marketRecipient: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    stableCoinFundRecipient: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    pancakeFac: "0x6725F303b657a9451d8BA641348b6761A6CC7a17",
    pancakeRouter: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1",
    pancakeFac1: "0xB7926C0430Afb07AA7DEfDE6DA862aE0Bde767bc",
    pancakeRouter1: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
    tha: "0xE794664b06F8E1CC50b7A9048d382E822B903E5d",
    ETH_PRICEFEED_ADDRESS: "0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7",
    BTC_PRICEFEED_ADDRESS: "0x5741306c21795FdCBb9b265Ea0255F499DFe515C",
    COMMUNITY_ADDRESS: "0x55cdae661119bdf31F604C21F4D36777f4EF1E51",
    THA_ADDRESS: "0x0d58ebd9C5494Ac68eBbEc0bb2A43c640F3486cd",
    USDT_ADDRESS: "0x4154a3560c8F4496B3dE5151759ede7861E321eE",
    THA_EXCHANGE_ADDRESS: "0xb9ba7e37A846Daf528631411950F98912e930AB2",
    FEE_RECEIVER_ADDRESS: "0x8572Bc67B7d32f2A66F1FDc0fd0E4a9a7b0c3DF2",
    OHM_ADDRESS: "0x880d0e2F8702b1336cB02F42aE831a071452f913",
    BONDINGCALC_ADDRESS: "0x7e2f6d04678A1CDE92951ea785176Bc06623BF0f",
    TUSDB_ADDRESS: "0x5eBdD2FFd00833f5A2a61e7E24076BF06040BfDc",
    TREASURY_ADDRESS: "0xc9a602Acd501B9e42e10657D07d51B9b85368258",
    DISTRIBUTOR_ADDRESS: "0xa331a395A94E4dD26097Cb6F8a732e155911540E",
    SOHM_ADDRESS: "0x3767B1d756d6F54A49BD9860C2b28f8606c952C1",
    STAKING_ADDRESS: "0x144fa215347FE4675bE8aB23250C1a74dF13BdeD",
    STAKING_WARMUP_ADDRESS: "0x886C2aDdD7A3d8Df0d31533Db95f3F99602B1E86",
    STAKING_HELPER_ADDRESS: "0xc9323a8e090FBa7a81c91D3A1729CD885D6d43Ec",
    DAI_BOND_ADDRESS: "0x129bDB0ea6a6D3d9D8169Df2690A7c2105176830",
    REDEEM_HELPER_ADDRESS: "0x82589041A54B581CeFD9a990B80DC8d348ae0dac",
    DEPOSIT_HELPER_ADDRESS: "0x14820a86f43D175Cf82b190A14FC1b804C3A1134",
    TURBINE_ADDRESS: "0xdD675DB9cdDc6ecf9eD1A2619CacEe13Dd016301",
    COMMUNITY_REWARD_ADDRESS: "0xEb04F74D28a8ee6bD90AE451d3c8D6769DD51e57",
    STAKING_REWARD_ADDRESS: "0x827E4801A4bbd43d8Fb35793a6E6D0fF37a722B8",
    ADDITION_STAKING_REWARD_ADDRESS: "0xe1c66b1896c47fdaF0617aD8B1B6F3d998E7Fc73",
    MINT_REWARD_ADDRESS: "0xb30fcB347c0156e262fC1b9cCb9e18A6548d8842",
    OHM_USDT_PAIR: "0xf9E8182Bc719e3EBf18a69b06Cf7f063319A3dfF",
    USDT_STABLE_BOND_ADDRESS: "0xaBe9bc6C91e5342e24ba16FA2a0778229f38d0Fa",
    ETH_ADDRESS: "0x6eDbC08B020bA160839c256c4125ec0441a52848",
    ETH_BOND_ADDRESS: "0x3930880b63365ff21b7CAc50696c1Ad9d10ae4B9",
    BTC_ADDRESS: "0x17EAc1634D37c5a543DE0ec8d0B6c2C9e2f6E1b9",
    BTC_BOND_ADDRESS: "0x065464194A3f937535d9D61EbDb8d75aaEa1cCe0",
    LOTTERY_ADDRESS: "0x2f6Bf141cf0B626403AF524014aa3fbf245Bdd14",
    DAO_POOL_ADDRESS: "0x50C31a427bA0890c90f5a17EF21449c130Dfc50e",
    "lottery-contract": "0x67b07Ac6E15c1f731477e79798923194693c8ac9",

    DAI_ADDRESS: "0x4154a3560c8F4496B3dE5151759ede7861E321eE",
    RPC: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },

  11155111: {
    tusdbRecipient: "0x38bEDCF21f54744697C50Ef166876F4B4BC1dAc2",
    accountant: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    lotteryLiquidator: "0x3FE4786a158bE5e4912157468E8e2d5F46cBe394",
    commRootAddress: "0x495BEfc1bFAB4908d3848f8f2DCcc770B17cd0c0",
    ratioOperator: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    lampFundRecipient: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    discipleFundRecipient: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    investmentRecipient: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    marketRecipient: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    stableCoinFundRecipient: "0x7A99690C3A58fa1D0f6975BbfffacC5f64c74978",
    epochLengthInBlocks: "10",
    pancakeRouter: "0x2A1b4E9e29974Ec9da7FDF8F76E4A6214f552BBB",
    pancakeFac: "0x6220d8cdAcF062761Ea0651998455A619F771ca1",
    ETH_PRICEFEED_ADDRESS: "0x694AA1769357215DE4FAC081bf1f309aDC325306",
    BTC_PRICEFEED_ADDRESS: "0x1b44F3514812d835EB1BDB0acB33d3fA3351Ee43",
    LINK_PRICEFEED_ADDRESS: "0xc59E3633BAAC79493d908e63626716e204A45EdF",
    USDC_PRICEFEED_ADDRESS: "0xA2F78ab2355fe2f984D808B5CeE7FD0A93D5270E",
    DAI_PRICEFEED_ADDRESS: "0x14866185B1962B63C3Ea9E03Bc1da838bab34C19",
    USDT_ADDRESS: "0x203DcCD8529c29C89a26dc201507827316389a92",
    LINK_ADDRESS: "0x448aA652d10De33900ACb6d360eD3DC2d1d4F080",
    USDC_ADDRESS: "0xfAB6C2532758c8b9B6E3EE16BfEe52A1ECe82826",
    DAI_ADDRESS: "0x203DcCD8529c29C89a26dc201507827316389a92",
    BTC_ADDRESS: "0x87dCdB1127848998bcA509cAecE7ae9C5fC409B0",
    ETH_ADDRESS: "0xb971524d266d4FE6D025feB62Ba2e9a26B765Ef5",
    FEE_RECEIVER_ADDRESS: "0xb2339aa11D59b9Ccd387ea82A45A893888FD7bE6",
    LOTTERY_ADDRESS: "0x1aF9E4C3b145bBEC8B61EF55df87AEE3e2d9806C",
    OHM_ADDRESS: "0xed2AFbAFf98bCbCba32BC350F738D91DC5C95424",
    OHM_USDT_PAIR: "0x5Ee2584E6f65DB7742aa5742c0acccc6a2322224",
    BONDINGCALC_ADDRESS: "0xe76A7CB130F122E4bdF3F36817408A832cd24Cf5",
    TURBINE_ADDRESS: "0xc4aC81E10F80F498328FCB8a7a6dd994a30bAe25",
    COMMUNITY_ADDRESS: "0x4b82D49a3Feb64AF925e766ccb970Ac4741F6A22",
    THA_ADDRESS: "0xEf6e12eE5025F8745FBc3efe8B37cAaA9b1b9E28",
    THA_EXCHANGE_ADDRESS: "0xFbA17D30213a4dbdF65CBce485B466AaA3172E41",
    TREASURY_ADDRESS: "0x993Ad119A289aE9E21E7DF0DcEB3E11896f5E160",
    TUSDB_ADDRESS: "0xFCa1047b1664057F2C9906BEc048131A08aECB79",
    DISTRIBUTOR_ADDRESS: "0xe5b2fdA5C742538A69ab8Aa62CEdbE82f11dF0EB",
    SOHM_ADDRESS: "0xc7FDeD74Cae8495c213cF2281efA01fDEe31B7a6",
    COMMUNITY_REWARD_ADDRESS: "0x403f42aca859418faD64ebF78d597E7908348187",
    DAO_POOL_ADDRESS: "0x8e97865969DDeA1e2f97BFB75335bF9146040600",
    STAKING_ADDRESS: "0x54A6985439CD9447F9d33EeC46DCF78eC70b0D41",
    STAKING_WARMUP_ADDRESS: "0x0cdA4D2cBC06842224cE8D7ef9AADEaF76491401",
    STAKING_HELPER_ADDRESS: "0x84FA803E2B8b437a7E69fe8FC6a7A08AFA61e5a0",
    DAI_BOND_ADDRESS: "0x3Bc1831B888285a4ca9e529D99ab9221312eF9c7",
    USDT_STABLE_BOND_ADDRESS: "0x30519e09605A9A2D7719Bd05bb7dd0C1935d8905",
    ETH_BOND_ADDRESS: "0x5EEceD4722869dCd863FAe421865b407a4c6FdC1",
    BTC_BOND_ADDRESS: "0x7321958559a79f0dbC5E1cAe4BB1322CC9cB658c",
    LINK_BOND_ADDRESS: "0x57B597a346645A038400078D6d573B15159673d2",
    DAI_STABLE_BOND_ADDRESS: "0x8416311aE612B0c81cf3a3Ab512a60b95f9666A6",
    USDC_STABLE_BOND_ADDRESS: "0x985c17659c636D80ab64f3009Ba00eEC3226cA57",
    REDEEM_HELPER_ADDRESS: "0x907ACd3F1a46c6575c8b9E8B38d8888D34E153fc",
    DEPOSIT_HELPER_ADDRESS: "0xe59D78382C75463217411D1892667d34d7741479",
    MINT_REWARD_ADDRESS: "0x4097c0D03fEcB956437791CA3294Af1129003770",
    weth: "0x2b1eE1Db6fc5612286Ce18A42686f31c6F62d2EC",
    codeHash: "0xa3392683b4aac1ef20b210065faee631ef443ad1292e9e438181e32a1e4c6f29",
    USDT_REAL_ADDRESS: "0xDE6851d28797A9E492Bb657803A875B13fc6a712",
  },
};
