import BondLogo from "../../components/BondLogo";
import { DisplayBondPrice, DisplayBondDiscount } from "../Bond/Bond.jsx";
import { Box, Button, Link, Paper, Typography, TableRow, TableCell, SvgIcon, Slide } from "@mui/material";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { NavLink } from "react-router-dom";
import "./choosebond.scss";
import { t, Trans } from "@lingui/macro";
import { Skeleton } from "@mui/material";
import useBonds from "src/hooks/Bonds";
import { useWeb3Context } from "../../hooks/web3Context";
import { useMobile } from "src/hooks";
export function BondDataCard({ bond }) {
  const { isSmallScreen } = useMobile();
  const { chainID } = useWeb3Context();
  const { loading } = useBonds(chainID);
  const isBondLoading = !bond.bondPrice ?? true;
  // console.log("isBondLoading", bond.name, bond.bondPrice, bond);
  return (
    <Slide direction="up" in={true}>
      <Paper
        id={`${bond.name}--bond`}
        className="bond-data-card ohm-card"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          mb: "30px !important",
        }}
      >
       

        <Box
          sx={{
            mb: isSmallScreen ? "20px !important" : "15px !important",
          }}
          className="bond-pair"
        >
          <BondLogo bond={bond} />
          <div className="bond-name">
            <Typography
              sx={{
                color: "#C7C8CC",
                fontFamily: "AGaramond LT",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {bond.displayName}
            </Typography>
            {/* {bond.isLP && (
              <div>
                <Link href={bond.lpUrl} target="_blank">
                  <Typography variant="body1">
                    <Trans>Deposit LP</Trans>
                    <SvgIcon component={ArrowUp} htmlColor="#A3A3A3" />
                  </Typography>
                </Link>
              </div>
            )} */}
          </div>
        </Box>
        <div className="data-row">
          <Typography
            sx={{
              color: "#868b8f",
              fontFamily: "AGaramond LT",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <Trans>Price</Trans>
          </Typography>
          <Typography
            className="bond-price"
            sx={{
              color: "#c7c8cc",
              fontFamily: "AGaramond LT",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <>{isBondLoading ? <Skeleton width="50px" /> : <DisplayBondPrice key={bond.name} bond={bond} />}</>
          </Typography>
        </div>
        <div className="data-row">
          <Typography
            sx={{
              color: "#868b8f",
              fontFamily: "AGaramond LT",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <Trans>ROI</Trans>
          </Typography>
          <Typography
            sx={{
              color: "#c7c8cc",
              fontFamily: "AGaramond LT",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            {isBondLoading ? <Skeleton width="50px" /> : <DisplayBondDiscount key={bond.name} bond={bond} />}
          </Typography>
        </div>

        <Box
          sx={{
            mb: isSmallScreen ? "20px !important" : 1.375,
          }}
          className="data-row"
        >
          <Typography
            sx={{
              color: "#868b8f",
              fontFamily: "AGaramond LT",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <Trans>Purchased</Trans>
          </Typography>
          <Typography
            sx={{
              color: "#c7c8cc",
              fontFamily: "AGaramond LT",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            {isBondLoading ? (
              <Skeleton width="80px" />
            ) : (
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }).format(bond.purchased)
            )}
          </Typography>
        </Box>
        <Link component={NavLink} to={`/stakelpbonds/${bond.name}`} style={(bond.name=='btc'||bond.name=='eth')?{pointerEvents:'none'}:{}}>
          <Button
            sx={{
              borderRadius: "30px",
              border: bond.name === "lgns_dai_lp" ? "1.3px solid rgb(139, 97, 246)" : "1.3px solid #B50C05",
              background:
                bond.name === "lgns_dai_lp" ? "rgb(139, 97, 246)" : "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
              boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
            }}
            variant="contained"
            fullWidth
            disabled={!bond.isAvailable[chainID]||bond.name=='eth'||bond.name=='btc'}
          >
            <Typography
              sx={{
                color: "#FFF",
                fontFamily: "AGaramond LT",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {!bond.isAvailable[chainID] ? t`Sold Out` : t`Bond ${bond.displayName}`}
            </Typography>
          </Button>
        </Link>
      </Paper>
    </Slide>
  );
}

export function BondTableData({ bond }) {
  const { chainID } = useWeb3Context();
  // Use BondPrice as indicator of loading.
  const isBondLoading = !bond.bondPrice ?? true;
  // const isBondLoading = useSelector(state => !state.bonding[bond]?.bondPrice ?? true);
  // console.log("isBondLoading", bond.name, bond.bondPrice, bond);

  return (
    <TableRow id={`${bond.name}--bond`}>
      <TableCell align="left" className="bond-name-cell" sx={{ borderBottom: "none" }}>
        <BondLogo bond={bond} />
        <Box className="bond-name" display="flex" alignItems={"center"} justifyContent={"center"}>
          <Typography
            sx={{
              color: "#C7C8CC",
              fontFamily: "AGaramond LT",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            {bond.displayName}
          </Typography>
          {/* {bond.isLP && (
            <Link color="primary" href={bond.lpUrl} target="_blank">
              <Typography variant="body1">
                <Trans>Deposit LP</Trans>
                <SvgIcon component={ArrowUp} htmlColor="#A3A3A3" />
              </Typography>
            </Link>
          )} */}
        </Box>
      </TableCell>
      <TableCell align="left" sx={{ borderBottom: "none" }}>
        <Typography
          sx={{
            color: "#C7C8CC",
            fontFamily: "AGaramond LT",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          <>{isBondLoading ? <Skeleton width="50px" /> : <DisplayBondPrice key={bond.name} bond={bond} />}</>
        </Typography>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          borderBottom: "none",
          color: "#C7C8CC",
          fontFamily: "AGaramond LT",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        {isBondLoading ? <Skeleton width="50px" /> : <DisplayBondDiscount key={bond.name} bond={bond} />}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          borderBottom: "none",
          color: "#C7C8CC",
          fontFamily: "AGaramond LT",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        {isBondLoading ? (
          <Skeleton />
        ) : (
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(bond.purchased)
        )}
      </TableCell>
      <TableCell
        sx={{
          borderBottom: "none",
          color: "#C7C8CC",
          fontFamily: "AGaramond LT",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        <Link component={NavLink} to={`/bonds/${bond.name}`}  style={(bond.name=='eth'||bond.name=='btc')?{pointerEvents:'none'}:{}}>
          <Button
            className="second-btn"
            sx={{
              height: "32px",
              borderRadius: "30px",
              border: "1.3px solid #B50C05",
              background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
              boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
            }}
            variant="contained"
            disabled={(!bond.isAvailable[chainID])||bond.name=='eth'||bond.name=='btc'}
          >
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              {!bond.isAvailable[chainID] ? t`Sold Out` : t`do_bond`}
            </Typography>
          </Button>
        </Link>
      </TableCell>
    </TableRow>
  );
}
