import { StableBond, LPBond, NetworkID, CustomBond, BondType } from "src/lib/Bond";
import { addresses } from "src/constants";
import BondOhmLusdHelperContractJson from "src/abi/BondHelper.json";

import { ReactComponent as BusdImg } from "src/assets/tokens/BUSD.svg";
import { ReactComponent as USDTImg } from "src/assets/tokens/USDT.svg";
import { ReactComponent as DaiImg } from "src/assets/tokens/bond_dai.svg";
import { ReactComponent as TokenDAIImg } from "src/assets/tokens/token-dai.svg";
// import { ReactComponent as TokenUSDTImg } from "src/assets/tokens/token-usdt.svg";
import { ReactComponent as FraxImg } from "src/assets/tokens/FRAX.svg";
import { ReactComponent as OhmFraxImg } from "src/assets/tokens/OHM-FRAX.svg";
import { ReactComponent as OhmLusdImg } from "src/assets/tokens/OHM-LUSD.svg";
import { ReactComponent as OhmEthImg } from "src/assets/tokens/OHM-WETH.svg";
import { ReactComponent as wETHImg } from "src/assets/tokens/wETH.svg";
import { ReactComponent as BTCBImg } from "src/assets/tokens/btcb.svg";
import { ReactComponent as LusdImg } from "src/assets/tokens/LUSD.svg";
import { ReactComponent as CvxImg } from "src/assets/tokens/CVX.svg";
import FraxOhmBondContractJson from "src/abi/bonds/OhmFraxContract.json";
import BondOhmDaiContractJson from "src/abi/bonds/OhmDaiContract.json";
import BondOhmLusdContractJson from "src/abi/bonds/OhmLusdContract.json";
import BondOhmEthContractJson from "src/abi/bonds/OhmEthContract.json";

import DaiBondContractJson from "src/abi/bonds/DaiContract.json";
import ReserveOhmLusdContractJson from "src/abi/reserves/OhmLusd.json";
import ReserveOhmDaiContractJson from "src/abi/reserves/OhmDai.json";
import ReserveOhmFraxContractJson from "src/abi/reserves/OhmFrax.json";
import ReserveOhmEthContractJson from "src/abi/reserves/OhmEth.json";

import FraxBondContractJson from "src/abi/bonds/FraxContract.json";
import LusdBondContractJson from "src/abi/bonds/LusdContract.json";
import EthBondContractJson from "src/abi/bonds/EthContract.json";
import CvxBondContractJson from "src/abi/bonds/CvxContract.json";
import StakeBondContractJson from "src/abi/bonds/StakeBond.json";

import ierc20AbiJson from "src/abi/IERC20.json";
import { getBondCalculator } from "src/helpers/BondCalculator";
import { BigNumberish } from "ethers";
import { getTokenPrice } from "src/helpers";

const BondOhmDaiContract = BondOhmDaiContractJson.abi;
const BondOhmLusdContract = BondOhmLusdContractJson.abi;
const BondOhmEthContract = BondOhmEthContractJson.abi;
const FraxBondContract = FraxBondContractJson.abi;
const LusdBondContract = LusdBondContractJson.abi;
const EthBondContract = EthBondContractJson.abi;
const StakeBondContract = StakeBondContractJson.abi;
const CvxBondContract = CvxBondContractJson.abi;
const FraxOhmBondContract = FraxOhmBondContractJson.abi;
const DaiBondContract = DaiBondContractJson.abi;
const BondOhmLusdHelperContract = BondOhmLusdHelperContractJson.abi;
const ReserveOhmDaiContract = ReserveOhmDaiContractJson.abi;
const ReserveOhmLusdContract = ReserveOhmLusdContractJson.abi;
const ReserveOhmFraxContract = ReserveOhmFraxContractJson.abi;
const ReserveOhmEthContract = ReserveOhmEthContractJson.abi;
const ierc20Abi = ierc20AbiJson.abi;

// TODO(zx): Further modularize by splitting up reserveAssets into vendor token definitions
//   and include that in the definition of a bond
export const usdt = new StableBond({
    name: "usdt",
    displayName: "USDT",
    bondToken: "USDT",
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
    bondIconSvg: USDTImg,
    bondContractABI: BondOhmDaiContract,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: "0xbE741d070c6d26109b95ddcD7B6da47395272f45",
            reserveAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
            bondHelperAddress: "0xbE741d070c6d26109b95ddcD7B6da47395272f45",
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x30519e09605A9A2D7719Bd05bb7dd0C1935d8905",
            reserveAddress: "0x203DcCD8529c29C89a26dc201507827316389a92",
            bondHelperAddress: "0x30519e09605A9A2D7719Bd05bb7dd0C1935d8905",
        },
    },
});



export const dai = new StableBond({
    name: "dai",
    displayName: "DAI",
    bondToken: "DAI",
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
    bondIconSvg: DaiImg,
    bondContractABI: BondOhmDaiContract,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: "0x59A6693CaA6305b1E4F16176D5228C32E24717E5",
            reserveAddress: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
            bondHelperAddress: "0x59A6693CaA6305b1E4F16176D5228C32E24717E5",
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x8416311aE612B0c81cf3a3Ab512a60b95f9666A6",
            reserveAddress: "0x81323b89e29ec45D703b312Fbf6E14DE9fE2A4cc",
            bondHelperAddress: "0x8416311aE612B0c81cf3a3Ab512a60b95f9666A6",
        },
    },
});

export const dai30 = new StableBond({
    name: "dai30",
    displayName: "DAI30",
    bondToken: "DAI",
    isStaked: true,
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true, },
    bondIconSvg: USDTImg,
    priceDecimal: 18,
    bondContractABI: StakeBondContract,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: addresses[NetworkID.Mainnet].DAI30_STABLE_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Mainnet].USDT_ADDRESS,
            bondHelperAddress: addresses[NetworkID.Mainnet].DAI30_STABLE_BOND_ADDRESS,
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x30519e09605A9A2D7719Bd05bb7dd0C1935d8905",
            reserveAddress: "0x203DcCD8529c29C89a26dc201507827316389a92",
            bondHelperAddress: "0x30519e09605A9A2D7719Bd05bb7dd0C1935d8905",
        },
        // [NetworkID.Localhost]: {
        //     bondAddress: addresses[NetworkID.Localhost].DAI30_STABLE_BOND_ADDRESS,
        //     reserveAddress: addresses[NetworkID.Localhost].USDT_ADDRESS,
        //     bondHelperAddress: addresses[NetworkID.Localhost].DAI30_STABLE_BOND_ADDRESS,
        // }
    },
});

export const dai60 = new StableBond({
    name: "dai60",
    displayName: "DAI60",
    bondToken: "DAI",
    isStaked: true,
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true, },
    bondIconSvg: USDTImg,
    priceDecimal: 18,
    bondContractABI: StakeBondContract,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: addresses[NetworkID.Mainnet].DAI60_STABLE_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Mainnet].USDT_ADDRESS,
            bondHelperAddress: addresses[NetworkID.Mainnet].DAI60_STABLE_BOND_ADDRESS,
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x30519e09605A9A2D7719Bd05bb7dd0C1935d8905",
            reserveAddress: "0x203DcCD8529c29C89a26dc201507827316389a92",
            bondHelperAddress: "0x30519e09605A9A2D7719Bd05bb7dd0C1935d8905",
        },
        // [NetworkID.Localhost]: {
        //     bondAddress: addresses[NetworkID.Localhost].DAI60_STABLE_BOND_ADDRESS,
        //     reserveAddress: addresses[NetworkID.Localhost].USDT_ADDRESS,
        //     bondHelperAddress: addresses[NetworkID.Localhost].DAI60_STABLE_BOND_ADDRESS,
        // }
    },
});

export const dai120 = new StableBond({
    name: "dai120",
    displayName: "DAI120",
    bondToken: "DAI",
    isStaked: true,
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false, },
    bondIconSvg: USDTImg,
    priceDecimal: 18,
    bondContractABI: StakeBondContract,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: addresses[NetworkID.Mainnet].DAI120_STABLE_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Mainnet].USDT_ADDRESS,
            bondHelperAddress: addresses[NetworkID.Mainnet].DAI120_STABLE_BOND_ADDRESS,
        },
        [NetworkID.Testnet]: {
            bondAddress: addresses[NetworkID.Testnet].DAI120_STABLE_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Testnet].USDT_ADDRESS,
            bondHelperAddress: addresses[NetworkID.Testnet].DAI120_STABLE_BOND_ADDRESS,
        },
        // [NetworkID.Localhost]: {
        //     bondAddress: addresses[NetworkID.Localhost].DAI120_STABLE_BOND_ADDRESS,
        //     reserveAddress: addresses[NetworkID.Localhost].USDT_ADDRESS,
        //     bondHelperAddress: addresses[NetworkID.Localhost].DAI120_STABLE_BOND_ADDRESS,
        // }
    },
});
export const dai240 = new StableBond({
    name: "dai240",
    displayName: "DAI240",
    bondToken: "DAI",
    isStaked: true,
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false, },
    bondIconSvg: USDTImg,
    priceDecimal: 18,
    bondContractABI: StakeBondContract,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: addresses[NetworkID.Mainnet].DAI240_STABLE_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Mainnet].USDT_ADDRESS,
            bondHelperAddress: addresses[NetworkID.Mainnet].DAI240_STABLE_BOND_ADDRESS,
        },
        [NetworkID.Testnet]: {
            bondAddress: addresses[NetworkID.Testnet].DAI240_STABLE_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Testnet].USDT_ADDRESS,
            bondHelperAddress: addresses[NetworkID.Testnet].DAI240_STABLE_BOND_ADDRESS,
        },
        // [NetworkID.Localhost]: {
        //     bondAddress: addresses[NetworkID.Localhost].DAI240_STABLE_BOND_ADDRESS,
        //     reserveAddress: addresses[NetworkID.Localhost].USDT_ADDRESS,
        //     bondHelperAddress: addresses[NetworkID.Localhost].DAI240_STABLE_BOND_ADDRESS,
        // }
    },

});
export const dai360 = new StableBond({
    name: "dai360",
    displayName: "DAI360",
    bondToken: "DAI",
    isStaked: true,
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false },
    bondIconSvg: USDTImg,
    priceDecimal: 18,
    bondContractABI: StakeBondContract,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: addresses[NetworkID.Mainnet].DAI360_STABLE_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Mainnet].USDT_ADDRESS,
            bondHelperAddress: addresses[NetworkID.Mainnet].DAI360_STABLE_BOND_ADDRESS,
        },
        [NetworkID.Testnet]: {
            bondAddress: addresses[NetworkID.Testnet].DAI360_STABLE_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Testnet].USDT_ADDRESS,
            bondHelperAddress: addresses[NetworkID.Testnet].DAI360_STABLE_BOND_ADDRESS,
        },
        // [NetworkID.Localhost]: {
        //     bondAddress: addresses[NetworkID.Localhost].DAI360_STABLE_BOND_ADDRESS,
        //     reserveAddress: addresses[NetworkID.Localhost].USDT_ADDRESS,
        //     bondHelperAddress: addresses[NetworkID.Localhost].DAI360_STABLE_BOND_ADDRESS,
        // }
    },
});




export const eth = new CustomBond({
    name: "eth",
    displayName: "wETH",
    lpUrl: "",
    bondType: BondType.StableAsset,
    bondToken: "wETH",
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
    bondIconSvg: wETHImg,
    bondContractABI: EthBondContract,
    reserveContract: ierc20Abi, // The Standard ierc20Abi since they're normal tokens
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: "0x2EaF2221f4A8234c2a4aF65DCA38Eaf3CCe52DF1",
            reserveAddress: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
            bondHelperAddress: "0x2EaF2221f4A8234c2a4aF65DCA38Eaf3CCe52DF1",
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x5EEceD4722869dCd863FAe421865b407a4c6FdC1",
            reserveAddress: "0xb971524d266d4FE6D025feB62Ba2e9a26B765Ef5",
            bondHelperAddress: "0x5EEceD4722869dCd863FAe421865b407a4c6FdC1",
        },
    },
    customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
        const ethBondContract = this.getContractForBond(networkID, provider);
        let ethPrice: BigNumberish = await ethBondContract.assetPrice();
        ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
        const token = this.getContractForReserve(networkID, provider);
        let ethAmount: BigNumberish = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
        ethAmount = Number(ethAmount.toString()) / Math.pow(10, 18);
        return ethAmount * ethPrice;
    },
});

export const btc = new CustomBond({
    name: "btc",
    displayName: "BTCB",
    lpUrl: "",
    bondType: BondType.StableAsset,
    bondToken: "BTCB",
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
    // bondIconSvg: wETHImg,
    bondIconSvg: BTCBImg,
    bondContractABI: EthBondContract,
    reserveContract: ierc20Abi, // The Standard ierc20Abi since they're normal tokens
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: "0x4337E95f99d28bE9a013a17306542F835f7e6373",
            reserveAddress: "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
            bondHelperAddress: "0x4337E95f99d28bE9a013a17306542F835f7e6373",
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x7321958559a79f0dbC5E1cAe4BB1322CC9cB658c",
            reserveAddress: "0x87dCdB1127848998bcA509cAecE7ae9C5fC409B0",
            bondHelperAddress: "0x7321958559a79f0dbC5E1cAe4BB1322CC9cB658c",
        },
    },
    customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
        const btcBondContract = this.getContractForBond(networkID, provider);
        let btcPrice: BigNumberish = await btcBondContract.assetPrice();
        btcPrice = Number(btcPrice.toString()) / Math.pow(10, 8);
        const token = this.getContractForReserve(networkID, provider);
        let btcAmount: BigNumberish = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
        btcAmount = Number(btcAmount.toString()) / Math.pow(10, 8);
        return btcAmount * btcPrice;
    },
});




// export const link = new CustomBond({
//   name: "link",
//   displayName: "LINK",
//   lpUrl: "",
//   bondType: BondType.StableAsset,
//   bondToken: "LINK",
//   isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
//   // bondIconSvg: wETHImg,
//   bondIconSvg: BTCBImg,
//   bondContractABI: EthBondContract,
//   reserveContract: ierc20Abi, // The Standard ierc20Abi since they're normal tokens
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0xE6295201CD1ff13CeD5f063a5421c39A1D236F1c",
//       reserveAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0x57B597a346645A038400078D6d573B15159673d2",
//       reserveAddress: "0x448aA652d10De33900ACb6d360eD3DC2d1d4F080",
//       bondHelperAddress: "0x57B597a346645A038400078D6d573B15159673d2",
//     },
//   },
//   customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
//     const linkBondContract = this.getContractForBond(networkID, provider);
//     let linkPrice: BigNumberish = await linkBondContract.assetPrice();
//     linkPrice = Number(linkPrice.toString()) / Math.pow(10, 8);
//     const token = this.getContractForReserve(networkID, provider);
//     let linkAmount: BigNumberish = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
//     linkAmount = Number(linkAmount.toString()) / Math.pow(10, 18);
//     return linkAmount * linkPrice;
//   },
// });

// export const cvx = new CustomBond({
//   name: "cvx",
//   displayName: "CVX",
//   lpUrl: "",
//   bondType: BondType.StableAsset,
//   bondToken: "CVX",
//   isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
//   bondIconSvg: CvxImg,
//   bondContractABI: CvxBondContract,
//   reserveContract: ierc20Abi, // The Standard ierc20Abi since they're normal tokens
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0x767e3459A35419122e5F6274fB1223d75881E0a9",
//       reserveAddress: "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0xd43940687f6e76056789d00c43A40939b7a559b5",
//       reserveAddress: "0xB2180448f8945C8Cc8AE9809E67D6bd27d8B2f2C", // using DAI per `principal` address
//       // reserveAddress: "0x6761Cb314E39082e08e1e697eEa23B6D1A77A34b", // guessed
//     },
//   },
//   customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
//     let cvxPrice: number = await getTokenPrice("convex-finance");
//     const token = this.getContractForReserve(networkID, provider);
//     let cvxAmount: BigNumberish = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
//     cvxAmount = Number(cvxAmount.toString()) / Math.pow(10, 18);
//     return cvxAmount * cvxPrice;
//   },
// });

// // the old convex bonds. Just need to be claimable for the users who previously purchased
// export const cvx_expired = new CustomBond({
//   name: "cvx-v1",
//   displayName: "CVX OLD",
//   lpUrl: "",
//   bondType: BondType.StableAsset,
//   bondToken: "CVX",
//   isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: true },
//   bondHelperContractABI: BondOhmLusdHelperContract,
//   bondIconSvg: CvxImg,
//   bondContractABI: CvxBondContract,
//   reserveContract: ierc20Abi, // The Standard ierc20Abi since they're normal tokens
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0x6754c69fe02178f54ADa19Ebf1C5569826021920",
//       reserveAddress: "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
//       bondHelperAddress: "0xe7e2c409944490974b0e634ea6f2af1297e44a52",
//     },
//     [NetworkID.Testnet]: {
//       bondAddress: "0xd43940687f6e76056789d00c43A40939b7a559b5",
//       reserveAddress: "0xB2180448f8945C8Cc8AE9809E67D6bd27d8B2f2C", // using DAI per `principal` address
//       // reserveAddress: "0x6761Cb314E39082e08e1e697eEa23B6D1A77A34b", // guessed
//       bondHelperAddress: "0xe7e2c409944490974b0e634ea6f2af1297e44a52",
//     },
//   },
//   customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
//     let cvxPrice: number = await getTokenPrice("convex-finance");
//     const token = this.getContractForReserve(networkID, provider);
//     let cvxAmount: BigNumberish = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
//     cvxAmount = Number(cvxAmount.toString()) / Math.pow(10, 18);
//     return cvxAmount * cvxPrice;
//   },
// });

export const ohm_dai = new LPBond({
    name: "lgns_dai_lp",
    displayName: "LGNS-DAI LP",
    bondToken: "USDT",
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false },
    bondIconSvg: TokenDAIImg,
    bondContractABI: BondOhmDaiContract,
    reserveContract: ReserveOhmDaiContract,
    bondHelperContractABI: BondOhmLusdHelperContract,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: "0x2EC37eAb8C1a3EA0eDa2296495bf7BE6c62e2788",
            reserveAddress: "0x882df4B0fB50a229C3B4124EB18c759911485bFb",
            bondHelperAddress: "0x8e2222C8b1e73E187e5AdA270707C11232E84630",
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x3Bc1831B888285a4ca9e529D99ab9221312eF9c7",
            reserveAddress: "0x5Ee2584E6f65DB7742aa5742c0acccc6a2322224",
            bondHelperAddress: "0xe59D78382C75463217411D1892667d34d7741479",
        },
    },
    lpUrl: "",
});


export const lpbond30 = new LPBond({
    name: "lgns_dai_lp_30",
    displayName: "LGNS-DAI LP(30)",
    bondToken: "USDT",
    isStaked: true,
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false,},
    bondIconSvg: TokenDAIImg,
    bondContractABI: StakeBondContract,
    reserveContract: ReserveOhmDaiContract,
    bondHelperContractABI: BondOhmLusdHelperContract,
    priceDecimal: 18,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: addresses[NetworkID.Mainnet].LP30_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Mainnet].OHM_USDT_PAIR,
            bondHelperAddress: addresses[NetworkID.Mainnet].LP30_DEPOSIT_HELPER_ADDRESS,
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x3Bc1831B888285a4ca9e529D99ab9221312eF9c7",
            reserveAddress: "0x5Ee2584E6f65DB7742aa5742c0acccc6a2322224",
            bondHelperAddress: "0xe59D78382C75463217411D1892667d34d7741479",
        },
        //    [NetworkID.Localhost]: {
        //        bondAddress: addresses[NetworkID.Localhost].LP30_BOND_ADDRESS,
        //        reserveAddress: addresses[NetworkID.Localhost].OHM_USDT_PAIR,
        //        bondHelperAddress: addresses[NetworkID.Localhost].LP30_DEPOSIT_HELPER_ADDRESS,
        //    },
    },
    lpUrl: "",
});
export const lpbond60 = new LPBond({
    name: "lgns_dai_lp_60",
    displayName: "LGNS-DAI LP(60)",
    bondToken: "USDT",
    isStaked: true,
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false,  },
    bondIconSvg: TokenDAIImg,
    bondContractABI: StakeBondContract,
    reserveContract: ReserveOhmDaiContract,
    bondHelperContractABI: BondOhmLusdHelperContract,
    priceDecimal: 18,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: addresses[NetworkID.Mainnet].LP60_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Mainnet].OHM_USDT_PAIR,
            bondHelperAddress: addresses[NetworkID.Mainnet].LP60_DEPOSIT_HELPER_ADDRESS,
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x3Bc1831B888285a4ca9e529D99ab9221312eF9c7",
            reserveAddress: "0x5Ee2584E6f65DB7742aa5742c0acccc6a2322224",
            bondHelperAddress: "0xe59D78382C75463217411D1892667d34d7741479",
        },
        // [NetworkID.Localhost]: {
        //     bondAddress: addresses[NetworkID.Localhost].LP60_BOND_ADDRESS,
        //     reserveAddress: addresses[NetworkID.Localhost].OHM_USDT_PAIR,
        //     bondHelperAddress: addresses[NetworkID.Localhost].LP60_DEPOSIT_HELPER_ADDRESS,
        // },
    },
    lpUrl: "",
});
export const lpbond120 = new LPBond({
    name: "lgns_dai_lp_120",
    displayName: "LGNS-DAI LP(120)",
    bondToken: "USDT",
    isStaked: true,
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false, },
    bondIconSvg: TokenDAIImg,
    bondContractABI: StakeBondContract,
    reserveContract: ReserveOhmDaiContract,
    bondHelperContractABI: BondOhmLusdHelperContract,
    priceDecimal: 18,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: addresses[NetworkID.Mainnet].LP120_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Mainnet].OHM_USDT_PAIR,
            bondHelperAddress: addresses[NetworkID.Mainnet].LP120_DEPOSIT_HELPER_ADDRESS,
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x3Bc1831B888285a4ca9e529D99ab9221312eF9c7",
            reserveAddress: "0x5Ee2584E6f65DB7742aa5742c0acccc6a2322224",
            bondHelperAddress: "0xe59D78382C75463217411D1892667d34d7741479",
        },
        // [NetworkID.Localhost]: {
        //     bondAddress: addresses[NetworkID.Localhost].LP120_BOND_ADDRESS,
        //     reserveAddress: addresses[NetworkID.Localhost].OHM_USDT_PAIR,
        //     bondHelperAddress: addresses[NetworkID.Localhost].LP120_DEPOSIT_HELPER_ADDRESS,
        // },
    },
    lpUrl: "",
});
export const lpbond240 = new LPBond({
    name: "lgns_dai_lp_240",
    displayName: "LGNS-DAI LP(240)",
    bondToken: "USDT",
    isStaked: true,
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false, },
    bondIconSvg: TokenDAIImg,
    bondContractABI: StakeBondContract,
    reserveContract: ReserveOhmDaiContract,
    bondHelperContractABI: BondOhmLusdHelperContract,
    priceDecimal: 18,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: addresses[NetworkID.Mainnet].LP240_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Mainnet].OHM_USDT_PAIR,
            bondHelperAddress: addresses[NetworkID.Mainnet].LP240_DEPOSIT_HELPER_ADDRESS,
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x3Bc1831B888285a4ca9e529D99ab9221312eF9c7",
            reserveAddress: "0x5Ee2584E6f65DB7742aa5742c0acccc6a2322224",
            bondHelperAddress: "0xe59D78382C75463217411D1892667d34d7741479",
        },
        //    [NetworkID.Localhost]: {
        //        bondAddress: addresses[NetworkID.Localhost].LP240_BOND_ADDRESS,
        //        reserveAddress: addresses[NetworkID.Localhost].OHM_USDT_PAIR,
        //        bondHelperAddress: addresses[NetworkID.Localhost].LP240_DEPOSIT_HELPER_ADDRESS,
        //    },
    },
    lpUrl: "",
});
export const lpbond360 = new LPBond({
    name: "lgns_dai_lp_360",
    displayName: "LGNS-DAI LP(360)",
    bondToken: "USDT",
    isStaked: true,
    isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false, },
    bondIconSvg: TokenDAIImg,
    bondContractABI: StakeBondContract,
    reserveContract: ReserveOhmDaiContract,
    bondHelperContractABI: BondOhmLusdHelperContract,
    priceDecimal: 18,
    networkAddrs: {
        [NetworkID.Mainnet]: {
            bondAddress: addresses[NetworkID.Mainnet].LP360_BOND_ADDRESS,
            reserveAddress: addresses[NetworkID.Mainnet].OHM_USDT_PAIR,
            bondHelperAddress: addresses[NetworkID.Mainnet].LP360_DEPOSIT_HELPER_ADDRESS,
        },
        [NetworkID.Testnet]: {
            bondAddress: "0x3Bc1831B888285a4ca9e529D99ab9221312eF9c7",
            reserveAddress: "0x5Ee2584E6f65DB7742aa5742c0acccc6a2322224",
            bondHelperAddress: "0xe59D78382C75463217411D1892667d34d7741479",
        },
        //    [NetworkID.Localhost]: {
        //        bondAddress: addresses[NetworkID.Localhost].LP360_BOND_ADDRESS,
        //        reserveAddress: addresses[NetworkID.Localhost].OHM_USDT_PAIR,
        //        bondHelperAddress: addresses[NetworkID.Localhost].LP360_DEPOSIT_HELPER_ADDRESS,
        //    },
    },
    lpUrl: "",
});

// export const ohm_weth = new CustomBond({
//   name: "ohm_weth_lp",
//   displayName: "OHM-WETH LP",
//   bondToken: "WETH",
//   isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
//   bondIconSvg: OhmEthImg,
//   bondContractABI: BondOhmEthContract,
//   reserveContract: ReserveOhmEthContract,
//   networkAddrs: {
//     [NetworkID.Mainnet]: {
//       bondAddress: "0xB6C9dc843dEc44Aa305217c2BbC58B44438B6E16",
//       reserveAddress: "0xfffae4a0f4ac251f4705717cd24cadccc9f33e06",
//     },
//     [NetworkID.Testnet]: {
//       // NOTE (unbanksy): using ohm-dai rinkeby contracts
//       bondAddress: "0xcF449dA417cC36009a1C6FbA78918c31594B9377",
//       reserveAddress: "0x8D5a22Fb6A1840da602E56D1a260E56770e0bCE2",
//     },
//   },
//   bondType: BondType.LP,
//   lpUrl:
//     "https://app.sushi.com/add/0x383518188c0c6d7730d91b2c03a03c837814a899/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
//   customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
//     if (networkID === NetworkID.Mainnet) {
//       const ethBondContract = this.getContractForBond(networkID, provider);
//       let ethPrice: BigNumberish = await ethBondContract.assetPrice();
//       ethPrice = Number(ethPrice.toString()) / Math.pow(10, 8);
//       const token = this.getContractForReserve(networkID, provider);
//       const tokenAddress = this.getAddressForReserve(networkID);
//       const bondCalculator = getBondCalculator(networkID, provider);
//       const tokenAmount = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
//       const valuation = await bondCalculator.valuation(tokenAddress, tokenAmount);
//       const markdown = await bondCalculator.markdown(tokenAddress);
//       let tokenUSD =
//         (Number(valuation.toString()) / Math.pow(10, 9)) * (Number(markdown.toString()) / Math.pow(10, 18));
//       return tokenUSD * Number(ethPrice.toString());
//     } else {
//       // NOTE (appleseed): using OHM-DAI on rinkeby
//       const token = this.getContractForReserve(networkID, provider);
//       const tokenAddress = this.getAddressForReserve(networkID);
//       const bondCalculator = getBondCalculator(networkID, provider);
//       const tokenAmount = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
//       const valuation = await bondCalculator.valuation(tokenAddress, tokenAmount);
//       const markdown = await bondCalculator.markdown(tokenAddress);
//       let tokenUSD =
//         (Number(valuation.toString()) / Math.pow(10, 9)) * (Number(markdown.toString()) / Math.pow(10, 18));
//       return tokenUSD;
//     }
//   },
// });

// HOW TO ADD A NEW BOND:
// Is it a stableCoin bond? use `new StableBond`
// Is it an LP Bond? use `new LPBond`
// Add new bonds to this array!!

// export const allBonds = [ohm_dai, usdt];
// export const allBonds = [ohm_dai, usdt, eth, btc, dai];
export const allBonds = [ohm_dai, usdt, dai];
export const stakeLPBonds = [lpbond30,lpbond60,lpbond120,lpbond240,lpbond360];
export const stakeStableBonds = [dai30,dai60,dai120,dai240,dai360];
// export const allBonds = [ohm_dai, usdt];
// export const allBonds = [ohm_dai, usdt, eth, btc];
// export const allBonds = [ohm_dai, usdt, eth, btc, link, dai, usdc];
// export const allBonds = [ohm_dai, usdt];
// export const allBonds = [ohm_dai, busd];
// export const allBonds = [ohm_dai, busd];
// TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
export const allExpiredBonds = [];
// export const allExpiredBonds = [cvx_expired];
export const allBondsMap = allBonds.reduce((prevVal, bond) => {
    return { ...prevVal, [bond.name]: bond };
}, {});

// Debug Log
export default allBonds;
