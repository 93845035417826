import { t, Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { QueryClient, QueryClientProvider } from "react-query";
import {
    Box,
    Paper,
    Zoom,
    Typography,
    Button,
    OutlinedInput,
    InputAdornment,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    TextField,
    Skeleton,
    Grid,
    Modal,
    Checkbox,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { trim, prettyVestingPeriod } from "src/helpers";

import NoData from "src/assets/images/noData.svg";
import {
    getTurbineData,
    getSilenceAmount,
    approveTurbine,
    turbineSilence,
    turbineRedeem,
    syncSilenceAmount,
} from "src/slices/TurbineSlice";
import { useDispatch } from "react-redux";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import { useAppSelector } from "src/hooks";
import { getBalances } from "src/slices/AccountSlice";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useMobile } from "src/hooks";

import { useAccount } from "wagmi";
import { error } from "../../slices/MessagesSlice";
import useDebounce from "../../hooks/Debounce";

import { PullToRefresh } from "src/components/PullToRefresh";
import { WebKeyBanner } from "./WebKeyBanner";
import { getStakeExInfo, getStakeExData, exStake, exStakeApprove, claimNFTShard, getOwnedNFT, getOwnedNFTShard, forgeNFT, approveArtisan, exStakeRedeem } from 'src/slices/StakeExSlice';
import EcosystemClaims from "./EcosystemClaims";
import nftImage from 'src/assets/images/webkey_nft.png'
import phoneImage from 'src/assets/images/webkey_phone.jpg'

import Chart from 'react-apexcharts'
import axiosInstance from "src/utils/axios";
import { SignForm } from "../auth/SignForm";
import { useAuthContext } from "src/auth/use-auth";
import { useQuery } from "react-query";
import { ecosystemRedeem, ecosystemStake, getEcosystemData, getEcosystemInfo, ecosystemApprove } from "src/slices/EcosystemSlice";
import CountDownTimer from "src/components/CountDownTimer";
import dayjs from "dayjs";
import { getAddress } from "@ethersproject/address";
import dogeEn from 'src/assets/images/en/doge-en.jpg'
import dogeZh from 'src/assets/images/zh/doge-zh.jpg'
import infoIcon from 'src/assets/icons/info.svg'

const OPEN_CLAIM = true;

const queryClient = new QueryClient();


const Banner = () => {
    const [showInfo, setShowInfo] = useState(false);
    const { isSmallScreen } = useMobile();
    return (<Zoom in={true} timeout={1000}>
        <Paper
            className="ohm-card linear-card"
            sx={{
                mb: isSmallScreen ? 1.875 : 3.125,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: isSmallScreen ? "12px" : "24px",
                    background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                    position: "relative",
                    p:1
                }}
            >
                <Box
                    sx={{
                        position: "relative",
                        zIndex: 1,
                    }}
                    display="flex"
                    flexDirection={isSmallScreen ? "column" : "row"}
                >
                    <Box
                        display="flex"
                        flexDirection={isSmallScreen ? "row" : "column"}
                    >
                        {
                            i18n.locale == 'zh' ? <img src={dogeZh} style={{ maxWidth: '100%' }} /> :
                                <img src={dogeEn} style={{ maxWidth: '100%' }} />
                        }
                    </Box>
                    <Box style={{position:'absolute',top:8,right:8, padding:4}} onClick={()=>{
                        setShowInfo(true);
                    }}>
                        <img src={infoIcon} style={{ width: 24, height: 24 }} />
                    </Box>
                </Box>
                <Modal open={showInfo} onClose={() => { setShowInfo(false) }} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <WebKeyBanner />
                </Modal>
            </Box>
        </Paper>
    </Zoom>
    )
}



const EcosystemAirdrop = ({ data }: any) => {
    const { isSmallScreen } = useMobile();
    return (
        <Paper className="ohm-card linear-card"
            sx={{
                mb: isSmallScreen ? 1.875 : 3.125,
                pb: 4
            }}>
            <Box sx={{width:'100%'}}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', px: 2, py: 1 }}>
                    <Typography
                        sx={{
                            color: "#868B8F",
                            fontFamily: "AGaramond LT",
                            fontSize: isSmallScreen ? "18px" : "26px",
                            fontStyle: "normal",
                            fontWeight: "400",
                        }}
                        align="center"
                    >
                        <Trans>Airdrop</Trans>
                    </Typography>
                </Box>
                <Box>
                    {data.map((item: any, index: any) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, py: 1,color:'#ccc' }}>
                            <Typography>{dayjs(item.createdAt).format('MM-DD HH:mm')}</Typography>
                            <Typography>{item.coin}</Typography>
                            <Typography>{Number(item.amount||0).toFixed(2)}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>


        </Paper>
    )
}

const Ecosystem = () => {
    const dispatch = useDispatch();
    // const [stakeExList, setStakeExList] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [notOpen, setNotOpen] = useState(true);
    const { provider, address, chainID, connected, connect } = useWeb3Context();
    const SECONDS_TO_REFRESH = 60;
    const daiBal = useAppSelector(state => state.account.balances.dai);
    const stakeExLoading = useAppSelector(state => state.ecosystem.loading);

    const unitCost = useAppSelector(state => state.ecosystem.unitCost);
    const available = useAppSelector(state => state.ecosystem.available);
    const stock = useAppSelector(state => state.ecosystem.stock);
    const period = useAppSelector(state => state.ecosystem.period);
    const allowance = useAppSelector(state => state.ecosystem.allowanceDAI);
    const stakeList = useAppSelector(state => state.ecosystem.stakeList);
    const stakeCount = useAppSelector(state => state.ecosystem.stakeCount);
    const [airdropData, setAirdropData] = useState([]);

    const chartOptions = {
        chart: {
            offsetY: -16,
            sparkline: {
                enabled: true,
            },
        },
        grid: {
            padding: {
                top: 24,
                bottom: 24
            }
        },
        legend: {
            show: false
        },
        plotOptions: {
            radialBar: {
                startAngle: -110,
                endAngle: 110,
                hollow: {
                    size: '56%'
                },
                dataLabels: {
                    name: {
                        offsetY: 8,
                    },
                    value: {
                        offsetY: -40,
                        color: '#eee'
                    },
                    total: {
                        show: true,
                        label: t`Available`,
                        color: '#888'
                    }
                },
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                colorStops: [
                    { offset: 0, color: '#ff6760', opacity: 1 },
                    { offset: 100, color: '#b50c05', opacity: 1 },
                ],
            },
        },
        dataLabels: {
            enabled: false,
        },

    };


    const phoneCount = useMemo(() => {
        if (stakeList?.length > 0) return Math.trunc(stakeList?.length / 10);
        else return 0;

    }, [stakeList])
    // const phoneCount = 2;

    const { isSmallScreen } = useMobile();
    const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);

    const pendingTransactions = useAppSelector(state => {
        return state.pendingTransactions;
    });

    const handleStake = async () => {
        await dispatch(ecosystemStake({ address, provider, networkID: chainID, quantity }));
        await dispatch(getEcosystemData({ address, provider, networkID: chainID }));
    }

    const handleApprove = async () => {
        await dispatch(ecosystemApprove({ address, provider, networkID: chainID, quantity }));
        await dispatch(getEcosystemData({ address, provider, networkID: chainID }));
    }

    const handleRedeem = async (id:any) => {
        await dispatch(ecosystemRedeem({ address, provider, networkID: chainID, id }));
        await dispatch(getEcosystemData({ address, provider, networkID: chainID }));
    }

    useEffect(() => {
        if (address) {
            dispatch(getEcosystemData({ address, provider, networkID: chainID }));
        }
        else {
            dispatch(getEcosystemInfo({ provider, networkID: chainID }))
        }
    }, [address]);

    const handleLoadMore = () => {
        dispatch(getEcosystemData({ address, provider, networkID: chainID, offset: stakeList.length }));
    }



    const loadAirdropData = async () => {
        const res = await axiosInstance.post(`${process.env.REACT_APP_ADMIN_API_URL}/stakeex2/airdrop`, { address:getAddress(address) });
        setAirdropData(res.data);
    }

    useEffect(() => {
        if(address){
            loadAirdropData();
        }
    }, [address]);
    // useEffect(() => {
    //     if (address) {
    //         axiosInstance.post(`${process.env.REACT_APP_ADMIN_API_URL
    //             }/stakeex/nft`, { address }).then(ret => {
    //                 setStakeExList(ret.data);
    //             })
    //     }
    // }, [address])



    const renderPhone = () => {
        const result = [];
        for (let i = 0; i < phoneCount; i++) {
            result.push(
                <Box key={'pkk_' + i} sx={{ mb: 1, display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <img src={phoneImage} style={{ maxWidth: '300px' }} />
                </Box>
            )
        }
        return result;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <PullToRefresh onRefresh={async () => {
                if (address) {
                    await dispatch(getEcosystemData({ address, provider, networkID: chainID }));
                    await loadAirdropData();
                }
                else {
                    await dispatch(getEcosystemInfo({ provider, networkID: chainID }))
                }
                return new Promise((resolve) => { setTimeout(() => resolve(true), 500) })
            }}>
                <Box
                    display="flex"
                    flexDirection={"column"}
                    alignItems="center"
                    sx={{
                        pt: isSmallScreen ? 2.5 : 0,
                        px: isSmallScreen ? 1.875 : 0,
                    }}
                >
                    {/* <WebKeyBanner /> */}
                    <Banner />
                    {
                        notOpen && <CountDownTimer onReachEnd={()=>{setNotOpen(false)}} targetDate={new Date('2024-10-11T00:00:00-04:00')} /> 
                    }
                    {
                        !notOpen&&<>
                        <Zoom in={true} timeout={1000}>
                        <Paper
                            className="ohm-card linear-card"
                            sx={{
                                mb: isSmallScreen ? 1.875 : 3.125,
                            }}
                        >
                            <Box
                                display={"flex"}
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: isSmallScreen ? "12px" : "24px",
                                    background: "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                                    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                                }}
                            // sx={{ pt: 5.75, pb: 3.75 }}
                            >

                                <Box sx={{ mb: 3.75, pt: 2 }}>
                                    <Typography
                                        sx={{
                                            color: "#868B8F",
                                            fontFamily: "AGaramond LT",
                                            fontSize: isSmallScreen ? "18px" : "26px",
                                            fontStyle: "normal",
                                            fontWeight: "400",
                                        }}
                                        align="center"
                                    >
                                        <Trans>Ecosystem Staking</Trans>
                                    </Typography>
                                    <Box sx={{ mt: 2, display: "flex", justifyContent: 'center' }}>
                                        {
                                            stakeExLoading ?
                                                <Skeleton variant="text" width={100}></Skeleton> :
                                                <Chart
                                                    dir="ltr"
                                                    type="radialBar"
                                                    series={[Math.trunc(available * 100 / stock)]}
                                                    options={chartOptions}
                                                    width="100%"
                                                    height={360}
                                                />
                                        }
                                    </Box>
                                </Box>
                                <Box sx={{ mb: 4 }}>
                                    {
                                        !address ? <Skeleton variant="text" width={120} height={45} /> :
                                            Number(allowance) >= (quantity * unitCost) ?
                                                <LoadingButton
                                                    className="stake-button"
                                                    variant="contained"
                                                    onClick={handleStake}
                                                    disabled={isPendingTxn(pendingTransactions, "ExStaking") || !connected}
                                                    loading={isPendingTxn(pendingTransactions, "ExStaking")}
                                                    // loadingPosition="end"
                                                    sx={{
                                                        // width: "100%",
                                                        // height: "40px",

                                                        borderRadius: "30px",
                                                        border: "1.3px solid #B50C05",
                                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                        boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                                        color: "#FFFFFF",
                                                        fontFamily: "AGaramond LT",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        mb: isSmallScreen ? "20px !important" : 0,
                                                        "&:hover": {
                                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                            boxShadow: "5px 5px 9px #070707, -6px -2px 16px #455059",
                                                        },
                                                    }}
                                                >
                                                    <Trans>Stake</Trans>
                                                </LoadingButton> :
                                                <LoadingButton
                                                    className="stake-button"
                                                    variant="contained"
                                                    disabled={isPendingTxn(pendingTransactions, "StakeExApproving")}
                                                    loading={isPendingTxn(pendingTransactions, "StakeExApproving") || stakeExLoading}
                                                    // loadingPosition="end"
                                                    onClick={() => {
                                                        handleApprove();
                                                    }}
                                                    sx={{
                                                        borderRadius: "30px",
                                                        border: "1.3px solid #B50C05",
                                                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        color: "#FFFFFF",
                                                        fontFamily: "AGaramond LT",
                                                        fontSize: "18px",
                                                        fontWeight: "700",
                                                        mb: isSmallScreen ? "20px !important" : 0,
                                                        "&:hover": {
                                                            background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                                            boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                                        },
                                                    }}
                                                >
                                                    <Trans>Approve</Trans>
                                                </LoadingButton>
                                    }
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "1px",
                                        background: "#0B0C0A",
                                        boxShadow: "0px -1.6383px 0px 0px rgba(83, 89, 102, 0.60)",
                                        mb: 5.75,
                                    }}
                                />
                                <Box
                                    sx={{ px: isSmallScreen ? 2.5 : 3.75, width: "100%" }}
                                    display={"flex"}
                                    flexDirection={"column"}
                                    // alignItems="center"
                                    justifyContent="center"
                                >
                                    <Box display="flex" sx={{ mb: 1.25 }}>
                                        <Typography
                                            sx={{
                                                color: "#C7C8CC",
                                                fontFamily: "AGaramond LT",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}
                                        >
                                            <Trans>Balance:</Trans>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: "#C7C8CC",
                                                fontFamily: "AGaramond LT",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {trim(Number(daiBal), 2)} DAI
                                        </Typography>
                                        <Box sx={{ ml: 'auto' }}>

                                            <Typography sx={{
                                                color: "#C7C8CC",
                                                fontFamily: "AGaramond LT",
                                                fontSize: "12px",
                                                fontStyle: "normal",
                                                fontWeight: "400",
                                            }}>
                                                <Trans>Spend</Trans>: {unitCost * quantity} DAI
                                            </Typography>

                                        </Box>
                                    </Box>

                                </Box>
                            </Box>
                        </Paper>


                    </Zoom>

                    <EcosystemClaims onLoadMore={handleLoadMore} stakeCount={stakeCount} claimList={stakeList || []} handleRedeem={handleRedeem} />
                    <EcosystemAirdrop data={airdropData} />
                        </>
                    }
                    
                    {/* <WebKeyNFTList address={address} isSmallScreen={isSmallScreen} /> */}

                </Box>
            </PullToRefresh>
        </QueryClientProvider>
    );
};

export default Ecosystem;
