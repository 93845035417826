import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { shorten, trim, prettyVestingPeriod } from "../../helpers";
import { redeemBond,redeemBondInterest } from "../../slices/BondSlice";
import BondLogo from "../../components/BondLogo";
import { Box, Button, Link, Paper, Typography, TableRow, TableCell, SvgIcon, Slide, Grid } from "@mui/material";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { NavLink } from "react-router-dom";
import "./choosebond.scss";
import { Skeleton } from "@mui/material";
import { useWeb3Context, useBonds } from "src/hooks";
import { isPendingTxn, txnButtonText, txnButtonTextGeneralPending } from "src/slices/PendingTxnsSlice";
import { LoadingButton } from "@mui/lab";
import { useStakeLPBonds } from "src/hooks/Bonds";



export function ClaimBondTableData({ userBond, isInvite = false }) {
    const dispatch = useDispatch();
    const { address, chainID, provider } = useWeb3Context();
    const { bonds, expiredBonds } = useBonds(chainID);

    const bond = userBond[1];
    const bondName = bond.bond;

    const isAppLoading = useSelector(state => state.app.loading ?? true);

    const currentBlock = useSelector(state => {
        return state.app.currentBlock;
    });

    const pendingTransactions = useSelector(state => {
        return state.pendingTransactions;
    });

    const vestingPeriod = () => {
        return prettyVestingPeriod(currentBlock, bond.bondMaturationBlock);
    };

    async function onRedeem({ autostake, id, type }) {
        // TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
        let currentBond = [...bonds, ...expiredBonds].find(bnd => bnd.name === bondName);
        await dispatch(
            redeemBond({ address, bond: currentBond, networkID: chainID, provider, autostake, id, isInvite, type }),
        );
    }

    const pendingClaim = () => {
        if (
            isPendingTxn(pendingTransactions, "redeem_all_bonds") ||
            isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
        ) {
            return true;
        }

        return false;
    };

    return (
        <TableRow id={`${bondName}--claim`}>
            <TableCell align="left" className="bond-name-cell" sx={{ borderBottom: "none", height: "76px" }}>
                <BondLogo bond={bond} />
                <div className="bond-name">
                    <Typography
                        sx={{
                            color: "#C7C8CC",
                            fontFamily: "AGaramond LT",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                        }}
                    >
                        {bond.displayName ? trim(bond.displayName, 4) : <Skeleton width={100} />}
                    </Typography>
                </div>
            </TableCell>
            <TableCell
                align="center"
                sx={{
                    borderBottom: "none",
                    height: "76px",
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                }}
            >
                {bond.pendingPayout ? trim(bond.pendingPayout, 4) : <Skeleton width={100} />}
            </TableCell>
            <TableCell
                align="center"
                sx={{
                    borderBottom: "none",
                    height: "76px",
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                }}
            >
                {bond.interestDue ? trim(bond.interestDue, 4) : <Skeleton width={100} />}
            </TableCell>
            <TableCell
                align="right"
                sx={{
                    borderBottom: "none",
                    height: "76px",
                    color: "#C7C8CC",
                    fontFamily: "AGaramond LT",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    whiteSpace: "nowrap",
                }}
            >
                {isAppLoading ? <Skeleton /> : vestingPeriod()}
            </TableCell>
            <TableCell align="right" sx={{ borderBottom: "none", height: "76px" }}>
                <LoadingButton
                    variant="contained"
                    sx={{
                        height: "32px",
                        borderRadius: "30px",
                        border: "1.3px solid #B50C05",
                        background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                        boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                    }}
                    disabled={isPendingTxn(pendingTransactions, "redeem_bond") || bond.pendingPayout == 0.0 || pendingClaim()}
                    loading={isPendingTxn(pendingTransactions, "redeem_bond")}
                    loadingPosition="end"
                    onClick={() => onRedeem({ autostake: false, id: bond.id, type: "redeem_bond" })}
                >
                    <Typography
                        sx={{
                            color: "#FFF",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                        }}
                    >
                        {txnButtonTextGeneralPending(pendingTransactions, "redeem_bond", "Claim")}
                    </Typography>
                </LoadingButton>
            </TableCell>
        </TableRow>
    );
}

export function ClaimBondCardData({ userBond, isInvite = false }) {
    const dispatch = useDispatch();
    const { address, chainID, provider } = useWeb3Context();
    // const { bonds, expiredBonds } = useBonds(chainID);
    const {lpBonds:bonds,expiredBonds} = useStakeLPBonds(chainID);

    const bond = userBond[1];
    const bondName = bond.bond;
    const currentBlock = useSelector(state => {
        return state.app.currentBlock;
    });

    const pendingTransactions = useSelector(state => {
        return state.pendingTransactions;
    });

    const vestingPeriod = () => {
        return prettyVestingPeriod(currentBlock, bond.bondMaturationBlock);
    };

    const pendingClaim = () => {
        if (
            isPendingTxn(pendingTransactions, "redeem_all_bonds") ||
            isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
        ) {
            return true;
        }

        return false;
    };

    async function onRedeemInterest({ autostake, id, type }) {
        // TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
        // let currentBond = [...bonds, ...expiredBonds].find(bnd => bnd.name === bondName);

        let currentBond = [...bonds, ...expiredBonds].find(bnd => bnd.name === bondName);

        console.log('[DEBUG]bondinterest:',currentBond,bonds,bondName);

        await dispatch(
            redeemBondInterest({ address, bond: currentBond, networkID: chainID, provider, autostake, id, isInvite, type }),
        );
    }

    async function onRedeem({ autostake, id, type }) {
        // TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
        // let currentBond = [...bonds, ...expiredBonds].find(bnd => bnd.name === bondName);
        let currentBond = [...bonds, ...expiredBonds].find(bnd => bnd.name === bondName);

        await dispatch(
            redeemBond({ address, bond: currentBond, networkID: chainID, provider, autostake, id, isInvite, type }),
        );
    }

    return (
        <Box id={`${bondName}--claim`} className="claim-bond-data-card bond-data-card" style={{ marginBottom: "30px" }}>
            <Box className="bond-pair">
                <BondLogo bond={bond} />
                <Box className="bond-name">
                    <Typography
                        sx={{
                            color: "#C7C8CC",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                        }}
                    >
                        {bond.displayName ? trim(bond.displayName, 4) : <Skeleton width={100} />}
                    </Typography>
                </Box>
            </Box>

            <div className="data-row">
                <Typography
                    sx={{
                        color: "#868b8f",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Claimable</Trans>
                </Typography>
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    {bond.pendingPayout ? trim(bond.pendingPayout, 4) : <Skeleton width={100} />}
                </Typography>
            </div>

            <div className="data-row">
                <Typography
                    sx={{
                        color: "#868b8f",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Pending</Trans>
                </Typography>
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    {bond.interestDue ? trim(bond.interestDue, 4) : <Skeleton width={100} />}
                </Typography>
            </div>

            <div className="data-row" style={{ marginBottom: "20px" }}>
                <Typography
                    sx={{
                        color: "#868b8f",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Fully Vested</Trans>
                </Typography>
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    {vestingPeriod()}
                </Typography>
            </div>
            {bond?.isStaked&&<div className="data-row">
                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    <Trans>Interest</Trans>
                </Typography>

                
                    <LoadingButton
                        variant="contained"
                        sx={{
                            height: "32px",
                                borderRadius: "30px",
                                border: "1.3px solid #B50C05",
                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                        }}
                        // loadingPosition="end"
                        loading={isPendingTxn(pendingTransactions, "redeem_bond")}
                        onClick={() => {
                            console.log('[DEBUG]bondisstaked:',bond);
                            onRedeemInterest({ item: bond, autostake: true, id: bond.id, type: "redeem_bond" })
                        }
                        }
                        disabled={isPendingTxn(pendingTransactions, "redeem_bond") || (bond.interestDue - bond.principal) <= 0.0 || pendingClaim()}
                    >
                        <Typography
                            sx={{
                                color: "#FFF",
                                fontFamily: "Sometype Mono, sans-serif",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                            }}
                        >
                            {txnButtonText(pendingTransactions, "redeem_bond", <Trans>Claim Interest</Trans>)}
                        </Typography>
                    </LoadingButton>
                

                <Typography
                    sx={{
                        color: "#c7c8cc",
                        fontFamily: "AGaramond LT",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                    }}
                >
                    {bond.interestDue ? trim((bond.interestDue - bond.principal), 4) : <Skeleton width={100} />}
                </Typography>
            </div>}
            <Box display="flex" justifyContent="space-around" alignItems="center" className="claim-bond-card-buttons">
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <LoadingButton
                            variant="contained"
                            sx={{
                                height: "32px",
                                borderRadius: "30px",
                                border: "1.3px solid #B50C05",
                                background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                            }}
                            loadingPosition="end"
                            loading={isPendingTxn(pendingTransactions, "redeem_bond")}
                            disabled={isPendingTxn(pendingTransactions, "redeem_bond") || bond.pendingPayout == 0.0 || pendingClaim()}
                            onClick={() => onRedeem({ autostake: false, id: bond.id, type: "redeem_bond" })}
                        >
                            <Typography
                                sx={{
                                    color: "#FFF",
                                    fontFamily: "AGaramond LT",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                }}
                            >
                                {txnButtonText(pendingTransactions, "redeem_bond", t`Claim`)}
                            </Typography>
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={6}>
                        {!isInvite && (
                            <LoadingButton
                                variant="contained"
                                sx={{
                                    height: "32px",
                                    borderRadius: "30px",
                                    border: "1.3px solid #B50C05",
                                    background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                                    boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                                }}
                                loadingPosition="end"
                                loading={isPendingTxn(pendingTransactions, "redeem_bond")}
                                onClick={() => onRedeem({ autostake: true, id: bond.id, type: "redeem_bond" })}
                                disabled={isPendingTxn(pendingTransactions, "redeem_bond") || bond.pendingPayout == 0.0 || pendingClaim()}
                            >
                                <Typography
                                    sx={{
                                        color: "#FFF",
                                        fontFamily: "AGaramond LT",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "normal",
                                    }}
                                >
                                    {txnButtonText(pendingTransactions, "redeem_bond", t`Claim and Stake`)}
                                </Typography>
                            </LoadingButton>
                        )}
                    </Grid>
                </Grid>


            </Box>
        </Box>
    );
}
